import React,{useState,useEffect} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import './AddEmployee.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const ViewEmployee = () => {

    const [data, setData] = useState({
      firstname: '',
      lastname:'',
      email:'',
      number:'',
      branchlocation:'',
      password:'',
      type:''
    })
  const [isview, setIsview] = useState(0)
  const [isadd, setIsadd] = useState(0)
  const [isupdate, setIsupdate] = useState(0)
  const [isdelete, setIsdelete] = useState(0)
  const [empedit, setEmpedit] = useState([])

  const [firstname, setFirstname] = useState('')             
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [location, setLocation] = useState('')
  const [password, setPassword] = useState('')
  const [type , setType] = useState('')

  const {id} = useParams();
//   alert(id)

  useEffect(() => {
    axios.post('https://quantraapi.emedha.in/epm-permission/quantra/v1/employee-permission-show-update',{
      id
    })
    .then(res =>{
       setFirstname(res.data.data[0].firstName)
       setLastname(res.data.data[0].lastName)
       setEmail(res.data.data[0].email)
       setNumber(res.data.data[0].mobileNumber)
       setLocation(res.data.data[0].location)
       setPassword(res.data.data[0].password)
       setType(res.data.data[0].type)
      console.log(res.data.data)
      
    })
    .catch(err =>{
      console.error('error',err)
    })
  },[id])



  
    const handleChange = (e) => {
      const {name,value} = e.target;
      setData({...data,[name]: value})
    }

    
    const [errors, setErrors] = useState({});

    const validate = () => {
      let tempErrors = {};
      if (data.firstname === '') tempErrors.firstname = 'First name is required.';
      if (data.lastname === '') tempErrors.lastname = 'Last name is required.';
      if (data.email === '') tempErrors.email = 'Email is required.';
      if (data.number === '') tempErrors.number = 'Contact number is required.';
      if (data.branchLocation === '') tempErrors.branchLocation = 'Branch location is required.';
      if (data.password === '') tempErrors.password = 'Password is required.';
      if (data.type === '') tempErrors.type = 'Type is required.';
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
   };

  

    const empsubmit = (e) => {

      e.preventDefault();

      
      axios.post('https://quantraapi.emedha.in/epm-permission/quantra/v1/employee-permission-update',{firstname,lastname,number,email,password,location,type,id})
      .then(res => {
        if(res.data.status===200){
          alert('Employee edited Successfully')
          
        }
      })
      .catch (err => {
        console.error('error', err);
      })
    };

    const viewhandle = () => {
      setIsview(isview === 1 ? 0 : 1)
      console.log('check', isview === 1 ? 0 : 1); 
    }

    const addhandle = () => {
      setIsadd(isadd === 1 ? 0 : 1)
      console.log('check', isadd === 1 ? 0 : 1); 
    }

    const updatehandle = () => {
      setIsupdate(isupdate === 1 ? 0 : 1)
      console.log('check', isupdate === 1 ? 0 : 1); 
    }

    const deletehandle = () => {
      setIsdelete(isdelete === 1 ? 0 : 1)
      console.log('check', isdelete === 1 ? 0 : 1); 
    }

   


    

      

  return (
    <>
        <h3>View Employee Details</h3>
    <Container style={{marginTop:'5vh'}}>
 {/* <Tabs
      defaultActiveKey="Employee Edit"
      id="uncontrolled-tab-example"
      className="mb-3 tab-custom"
      style={{color:'black'}}
    >

      
      <Tab eventKey="Employee Details" title="Employee Details"  style={{color:'black'}}> */}
        <form onSubmit={empsubmit}>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>
                
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px'}}>
                FirstName:
            </label>
            <input
                 type="text"
                name="firstname"
                required
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter First Name"
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Email:
            </label>
            <input
                 type="text"
                 name="email"
                 required
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 placeholder="Enter Email"
                 style={{ width: "30vw", outline: "none" }}
               />
               
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Contact No:
            </label>
            <input
                 type="text"
                name="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter Contact No"
                pattern="[1-9]{1}[0-9]{9}"
                maxLength={10}
                required
                style={{ width: "30vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                BranchLocation:
            </label>
            <input
                 type="text"
                name="branchlocation"
                required
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="branch location"
               />
            </div>
            </div>
            <div className='formRight d-flex flex-column gap-2'>
            <div className='d-flex flex-column'>           
            <label>
                LastName:
            </label>
            <input
                 type="text"
                name="lastname"
                required
                value={lastname}
                 onChange={(e) => setLastname(e.target.value)}
                 placeholder="Enter Last Name"
                 style={{ width: "30vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Password:
            </label>
                <input type="password" name="password" value={password}  onChange={(e) =>setPassword(e.target.value)} placeholder='Enter Employee Password' style={{width:"30vw" ,outline:"none"}} />
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Type:
            </label>
            <input
                 type="text"
                name="firstname"
                required
                value={type}
                onChange={(e) => setType(e.target.value)}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="type"
               />
            </div>
            </div>    
            </div>
            {/* <Button variant="success" className='my-5 px-5 py-2' style={{marginTop:"10px"}} type='submit'>
              update
           </Button> */}
        </form>
      {/* </Tab>
     
   
    </Tabs> */}

    </Container>
    </>
  )
}

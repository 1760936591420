import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Dashboard.css'
import revenue from '../../Images/150.png'
import order from '../../Images/120.png'
import avgprize from '../../Images/fail (1).png'

function Dashboard() {

  //code
 return (
  <>
     <Container style={{marginTop:'5vh'}}>
     <div className='dashboardHeading d-flex justify-content-between mb-3'>
      <div className='headingLeft'>
         User Dashboard
      </div>
      {/* <div className='headingRight'>
        <span className=' dashboard mr-1'>Dashboard</span>/
        <span className='userDashboard ml-1'>User Dashboard</span>

      </div> */}

    </div>
    <div className="cards d-flex flex-wrap gap-4">
   <div className='cardOne d-flex justify-content-between p-4 shadow-sm align-items-center'>
     <div className='cardContent'>
         <p className="cardTextOne">Orders</p>
        <p className='cardTextTwo'>1,232</p>
            </div>
   <div className='cardIcon'><img src={order} style={{ width: 50, height: 50 }}/> </div>
 </div>
 <div className='cardTwo d-flex justify-content-between p-4 shadow-sm align-items-center'>
     <div className='cardContent'>
         <p className="cardTextOne"> Revenue</p>
        <p className='cardTextTwo'> $ 1,232</p>
            </div>
   <div className='cardIcon'> <img src={revenue}style={{ width: 50, height: 50 }} /> </div>
 </div>
 <div className='cardTwo d-flex justify-content-between p-4 shadow-sm align-items-center' >
     <div className='cardContent'>
         <p className="cardTextOne">Average Price</p>
        <p className='cardTextTwo'> $ 16.2</p>
            </div>
   <div className='cardIcon'><img src={avgprize} style={{ width: 50, height: 50 }} /> </div>
 </div>
    </div>
    </Container>
    </>
 );
}

export default Dashboard;
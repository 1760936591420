import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function Viewreportanalysis() {
    const [data, setData] = React.useState('')
    const { id } = useParams()

    React.useEffect(() => {
        axios.post('https://quantraapi.emedha.in/reportanalysis/analysisdetail/vr',{
          id
        })
        .then(res =>{
         
          console.log(res.data.data)
          setData(res.data.data)
          
        })
        .catch(err =>{
          console.error('error',err)
        })
      },[id])
    
  return (
    <div>
         <Container style={{marginTop:''}}>
 

         <div className='heading d-flex justify-content-between' style={{marginTop:''}}>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>Report Analysis</h5></div>
       <div className='headingRight'> Dashboard/Report Analysis</div>
     </div>
 <div className='mainContent'>
 <div className='pageContent'>
  
  <div className='card-body'>
    <div className="table-responsive">
      <div className='row11 d-flex justify-content-between align-items-center mt-4'>
        
      </div>

{/* table */}


  <Table striped bordered>
    <thead>
      <tr >
        <th colSpan='2'><span className='d-flex align-items-center gap-2'><span> Report Analysis Details </span><span></span> </span></th>
        {/* <td className='sno' colSpan='2'>  Survery Details </td> */}
       
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className='sno'> ISell No.</td>
        <td className='firstname'>{data.isell_no}</td>
       
      </tr>
      <tr>
      <td className='sno'>Order Mode</td>
        <td className='firstname'>{data.orderMode}</td>
       
      </tr>

      <tr>
      <td className='sno'>Technician Name</td>
        <td className='firstname'>{data.technician_name}</td>
      </tr>
      <tr>
      <td className='sno'>Customer Name</td>
        <td className='firstname'>{data.customerName}</td>
      </tr>
      <tr>
      <td className='sno'>Mobile Number</td>
        <td className='firstname'>{data.mobile}</td>
      </tr>
      <tr>
      <td className='sno'>Email</td>
        <td className='firstname'>{data.email}</td>
      </tr>
      <tr>
      <td className='sno'>Address</td>
        <td className='firstname'>{data.address}</td>
      </tr>
      <tr>
      <td className='sno'>Country</td>
        <td className='firstname'>{data.india}</td>
      </tr>
      <tr>
      <td className='sno'>State</td>
        <td className='firstname'>{data.state}</td>
      </tr>
      <tr>
      <td className='sno'>City</td>
        <td className='firstname'>{data.city}</td>
      </tr>
      <tr>
      <td className='sno'>Pin Code</td>
        <td className='firstname'>{data.pin_code}</td>
      </tr>
      <tr>
      <td className='sno'>Sales Representative</td>
        <td className='firstname'>{data.sales_rep}</td>
      </tr>
      <tr>
      <td className='sno'>Lead Status</td>
        <td className='firstname'><Badge bg="success">{data.lead_status}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Description</td>
        <td className='firstname'>{data.description}</td>
      </tr>
      <tr>
      <td className='sno'>Color</td>
        <td className='firstname'>{data.colour}</td>
      </tr>
      <tr>
      <td className='sno'>Date</td>
        <td className='firstname'><Badge bg="success">{data.date}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Concern</td>
        <td className='firstname'>{data.concern}</td>
      </tr>
      <tr>
      <td className='sno'>Measurement Start Date</td>
        <td className='firstname'><Badge bg="success">{data.meas_start_date}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Measurement End Date</td>
        <td className='firstname'><Badge bg="success">{data.meas_end_date}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Drawn By </td>
        <td className='firstname'>{data.drawn_by}</td>
      </tr>
      <tr>
      <td className='sno'>Approved By </td>
        <td className='firstname'>{data.approved_by}</td>
      </tr>
      <tr>
      <td className='sno'>Fabrication Request on</td>
        <td className='firstname'><Badge bg="success">{data.fabReqOn}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Fabrication Start Date</td>
        <td className='firstname'><Badge bg="success">{data.fabStartDate}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Fabrication End Date</td>
        <td className='firstname'><Badge bg="success">{data.fabEndDate}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Fabrication Slab ISsue</td>
        <td className='firstname'><Badge bg="success">{data.fab_slabIssued}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Fabrication Done By</td>
        <td className='firstname'>{data.fab_done_by}</td>
      </tr>
      <tr>
      <td className='sno'>Fabrication Doc Prepare</td>
        <td className='firstname'>{data.fab_docPrepare}</td>
      </tr>
      <tr>
      <td className='sno'>Fabrication Remark</td>
        <td className='firstname'>{data.fabRemark}</td>
      </tr>
      <tr>
      <td className='sno'>OCN Update No</td>
        <td className='firstname'>{data.doc_OCN_Update_No}</td>
      </tr>
      <tr>
      <td className='sno'>OCN Approved By</td>
        <td className='firstname'>{data.OCN_Approved_by}</td>
      </tr>
      <tr>
      <td className='sno'>OCN Approved Date and Time</td>
        <td className='firstname'><Badge bg="success">{data.Doc_Ocn_Approved_date_and_time}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Doc Audit Remark:</td>
        <td className='firstname'>{data.docs_audit_remark}</td>
      </tr>
      <tr>
      <td className='sno'>Packaging Date</td>
        <td className='firstname'><Badge bg="success">{data.packeging_date}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Packeging Remark</td>
        <td className='firstname'>{data.packeging_remark}</td>
      </tr>
      <tr>
      <td className='sno'>Log Material Date and Time</td>
        <td className='firstname'><Badge bg="success">{data.Log_materialDateAndTime}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Lorry Number</td>
        <td className='firstname'>{data.log_lorry_no}</td>
      </tr>
      <tr>
      <td className='sno'>Delivery Challan</td>
        <td className='firstname'>{data.log_delivery_challan}</td>
      </tr>
      <tr>
      <td className='sno'>Drivery Name</td>
        <td className='firstname'>{data.log_driver_name}</td>
      </tr>
      <tr>
      <td className='sno'>Dispatch Delivery Date and Time</td>
        <td className='firstname'><Badge bg="success">{data.despatch_delivery_date_time}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Dispatch Delivery By</td>
        <td className='firstname'>{data.desptach_delivered_by}</td>
      </tr>
      <tr>
      <td className='sno'>Despatch Concern</td>
        <td className='firstname'>{data.despatch_concerns}</td>
      </tr>
      <tr>
      <td className='sno'>Delivery Destination</td>
        <td className='firstname'>{data.log_delivery_destination}</td>
      </tr>
      <tr>
      <td className='sno'>Dispatched Ordered Date </td>
        <td className='firstname'><Badge bg="success">{data.log_despatched_order_date}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Dispatched Update Delivery Date and Time </td>
        <td className='firstname'><Badge bg="success">{data.despatchUpdateDeliveryDateAndTime}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Delivery final Confirmation Date and Time </td>
        <td className='firstname'><Badge bg="success">{data.delivery_final_confirmation_date_time}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Staff ID</td>
        <td className='firstname'>{data.staff_id}</td>
      </tr>
      <tr>
      <td className='sno'>Installation Date and Time </td>
        <td className='firstname'><Badge bg="success">{data.installation_date_time}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Installed By</td>
        <td className='firstname'>{data.installed_By}</td>
      </tr>
      <tr>
      <td className='sno'>Installation Concern</td>
        <td className='firstname'>{data.installation_concerns}</td>
      </tr>
      <tr>
      <td className='sno'>Installation Conformation Date and Time </td>
        <td className='firstname'><Badge bg="success">{data.installation_confirmation_final_date_time}</Badge></td>
      </tr>
      <tr>
      <td className='sno'>Tech Id</td>
        <td className='firstname'>{data.tech_id}</td>
      </tr>
      <tr>
      <td className='sno'>QC Approval Date and Time </td>
        <td className='firstname'><Badge bg="success">{data.QcApprovalDateAndTime}</Badge></td>
      </tr>
      
    </tbody>
  </Table>
    </div>     
  </div>
 </div>
 </div>
 </Container>
    </div>
  )
}

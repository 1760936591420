import React,{useState} from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';

export default function DeliveryUpdate() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case
   
  'firstname':
          setFirstName(value);
          break;
        case
   
  'lastname':
          setLastName(value);
          break;
        case
   
  'email':
          setEmail(value);
          break;
        case 'number':
          setNumber(value);
          break;
        case 'branchLocation':
          setBranchLocation(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'type':
          setType(value);
          break;
        default:
          break;
      }
    };
  return (
    <div>
          <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"black",fontWeight:'500'}}>QC DETAILS UPDATE</h5></div>
     </div>
          
    

        {/* <Container className='my-5'> */}
        <form action="">
            <div className='my-4'>
            <h3>job Information</h3>
            </div>
        <div className='col-md-12'>
                
                <div className='row'>
                 
                <div className='col-md-12 mb-2'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                ISELL No
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Order Received On
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                    
                </div>
                {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                    <button>+ Add more</button>
                </div> */}
                </div>
               
                </div>

                <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Customer Name
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Address
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                </div>
                
                </div>

               
                 
                 <Container className='my-4'>
                        <h2>Final CAD Drawing</h2>
                        <div className='row'>
                 
                 <div className='col-md-12 mb-2'>
                 </div>        
                  {/* ------------------ */}
                 <div className='col-md-4'>  
                     <Button variant='success'> view</Button>
                 </div>
     
                 
                 </div>
                

                </Container>

               

                <Container className='my-4'>
                        <h2>Images Uploads</h2>
                        <div className='row'>
                 
                 <div className='col-md-12 mb-2'>
                 </div>        
     
                 <div className='col-md-4'>  
                 <label>
                 Site images before delivery 
                 </label>
                 <input
                      type="file"
                     name="firstname"
                     value={firstName}
                     onChange={handleInputChange}
                      style={{ width: "25vw", outline: "none" }}
                      placeholder="ISSEL NO"
                    />
                 </div>
     
                 <div className='col-md-2'>           
                 <label>
                 Site images after delivery
                 </label>
                 
                 <input
                      type="file"
                     name="firstname"
                     value={firstName}
                     onChange={handleInputChange}
                      style={{ width: "25vw", outline: "none" }}
                      placeholder="ISSEL NO"
                    />
                     
                 </div>
                 {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                     <button>+ Add more</button>
                 </div> */}
                 </div>
                 <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Delivery Date & Time
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Delivered By
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />                </div>
                </div>
               
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Vehicle driver name
                </label>
                <select name='type' style={{width:"25vw" ,outline:"none"}} value={type}
                 onChange={handleInputChange} >
                <option value="">Select </option>
                <option value="Marketing Executive<">RAMU B</option>
                
               </select>
                
                </div>
    
                <div className='col-md-2'>           
                <label>
                Delivered to
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />                </div>
                </div>
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Delivered to phone
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />   
                
                </div>
                <div className='col-md-4'>  
                <label>
                Concerns
                </label><br />
                <textarea name="" id="" cols="40" rows="2"></textarea>   
                
                </div>
    
               
                </div>
               {/* ------------------ */}
                </div> 

                </Container>

                
        <Button variant="primary" className='my-2'>
                Submit
      </Button>
        </form>
    </div>
  )
}

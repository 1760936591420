import logo from './logo.svg';
import './App.css';
import Navbar from './Components/NavgationHeader/Navbar';
import SideBar from './Components/DasboardHeader/SideBar';
import { BrowserRouter } from 'react-router-dom';
import Entry from './Components/SideMainRender/Entry/Entry';
import LoginPage from './Components/Loginpage/LoginPage';
function App() {
  return (
    <BrowserRouter>
      {
        sessionStorage.getItem('login') && (
          <SideBar />
        )
      }
      
      {
        !sessionStorage.getItem('login') && (
          <LoginPage/>
        )
      }
      </BrowserRouter>
  );
}

export default App;

import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';

export default function ViewSurveryReport() {
  return (
    <div>
         <Container style={{marginTop:''}}>
 

         <div className='heading d-flex justify-content-between' style={{marginTop:''}}>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>Site Survey Report</h5></div>
       <div className='headingRight'> Dashboard/Site Survey Report</div>
     </div>
 <div className='mainContent'>
 <div className='pageContent'>
  
  <div className='card-body'>
    <div className="table-responsive">
      <div className='row11 d-flex justify-content-between align-items-center mt-4'>
        
      </div>

{/* table */}


  <Table striped bordered>
    <thead>
      <tr >
        <th colSpan='2'><span className='d-flex align-items-center gap-2'><span> Survery Details </span><span></span> </span></th>
        {/* <td className='sno' colSpan='2'>  Survery Details </td> */}
       
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className='sno'> ISell No.</td>
        <td className='firstname'>20262</td>
       
      </tr>
      <tr>
      <td className='sno'>Reference MOC No.</td>
        <td className='firstname'>123456</td>
       
      </tr>

      <tr>
      <td className='sno'>Technician Name</td>
        <td className='firstname'>Mark</td>
      </tr>
      <tr>
      <td className='sno'>Customer Name</td>
        <td className='firstname'>Mark</td>
      </tr>
      <tr>
      <td className='sno'>Measurement Date</td>
        <td className='firstname'>Mark</td>
      </tr>
      <tr>
      <td className='sno'>Address</td>
        <td className='firstname'> opposite the jubilee hills club</td>
      </tr>
      <tr>
      <td className='sno'>1. Is the site ready for Installation Services?</td>
        <td className='firstname'><Badge bg="success">yes</Badge></td>
      </tr>
      <tr>
      <td className='sno'>a. Cabinet installed and levelled ?</td>
        <td className='firstname'><Badge bg="success">yes</Badge></td>
      </tr>
      <tr>
      <td className='sno'>b. In case of old home, existing countertop removed ?</td>
        <td className='firstname'><Badge bg="success">yes</Badge></td>
      </tr>
      <tr>
      <td className='sno'>c. Sink, Faucet, Cooktop details finalised ?</td>
        <td className='firstname'><Badge bg="success">yes</Badge></td>
      </tr>
      <tr>
      <td className='sno'>d. Bracket on cabinet have been installed ?</td>
        <td className='firstname'><Badge bg="success">yes</Badge></td>
      </tr>
      <tr>
      <td className='sno'>2. Sinks have been received?</td>
        <td className='firstname'><Badge bg="success">yes</Badge></td>
      </tr>
      <tr>
      <td className='sno'>3. Cooktops have been received?</td>
        <td className='firstname'><Badge bg="success">yes</Badge></td>
      </tr>
      <tr>
      <td className='sno'>4. How would you best describe the job site?</td>
        <td className='firstname'><Badge bg="success">new home</Badge></td>
      </tr>
      <tr>
      <td className='sno'>5. Which floor of building will the installation be performed?</td>
        <td className='firstname'>8</td>
      </tr>
      <tr>
      <td className='sno'>6. If not on the ground floor, Is there is a lift which can be and is allowed to be used for carrying the counter top ?</td>
        <td className='firstname'>yes</td>
      </tr>
      <tr>
      <td className='sno'>a. Width of opening door:</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>b. Weight capacity:</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>c. Inner dimensions:</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>Length:</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>Width:</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>Height</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>Breadth</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>8. If the lift is not suitable, is there any alternative access?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>9. What are the dimensions and nature of alternative access?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>10. What is the height in metres from ground to the access point?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>11. What is the condition and construction of the access floor(s) from the vehicle parking <br /> area to the installation site?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>12. Are there any narrow passageways, pipes, electrical conduits etc. that may cause obstruction?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>13. Are there any parking/unloading restrictions outside the building?</td>
        <td className='firstname'> Are there any parking/unloading restrictions outside the building?</td>
      </tr>
      <tr>
      <td className='sno'>14. Has the customer been told to take permission fr relevant authorities including society to unload <br /> the vehicle and to carry out installation job at the site?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>15. Are there any restriction on time during which installation services cannot be carried out in the society?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>If yes, what are the timings during which installation cannot be carried out?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>16. Are there any issues or obstacles which could cause in effective installation and should be brought <br /> to the attention of installation team?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>17. Is there any work in process at the job site where installation has to be performed?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>18. Is there any other information which requires attention of the installation team?</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>Customer Signature</td>
        <td className='firstname'>800</td>
      </tr>
      <tr>
      <td className='sno'>Technician Signature</td>
        <td className='firstname'>800</td>
      </tr>
    </tbody>
  </Table>
    </div>     
  </div>
 </div>
 </div>
 </Container>
    </div>
  )
}

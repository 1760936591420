import React,{useState,useEffect} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import './AddEmployee.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const EditEmployee = () => {

    const [data, setData] = useState({
      firstname: '',
      lastname:'',
      email:'',
      number:'',
      branchlocation:'',
      password:'',
      type:''
    })
  const [isview, setIsview] = useState(0)
  const [isadd, setIsadd] = useState(0)
  const [isupdate, setIsupdate] = useState(0)
  const [isdelete, setIsdelete] = useState(0)
  const [empedit, setEmpedit] = useState([])

  const [firstname, setFirstname] = useState('')             
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [location, setLocation] = useState('')
  const [password, setPassword] = useState('')
  const [type , setType] = useState('')

  const {id} = useParams();
//   alert(id)

  useEffect(() => {
    axios.post('https://quantraapi.emedha.in/epm-permission/quantra/v1/employee-permission-show-update',{
      id
    })
    .then(res =>{
       setFirstname(res.data.data[0].firstName)
       setLastname(res.data.data[0].lastName)
       setEmail(res.data.data[0].email)
       setNumber(res.data.data[0].mobileNumber)
       setLocation(res.data.data[0].location)
       setPassword(res.data.data[0].password)
       setType(res.data.data[0].type)
      console.log(res.data.data)
      
    })
    .catch(err =>{
      console.error('error',err)
    })
  },[id])



  
    const handleChange = (e) => {
      const {name,value} = e.target;
      setData({...data,[name]: value})
    }

    
    const [errors, setErrors] = useState({});

    const validate = () => {
      let tempErrors = {};
      if (data.firstname === '') tempErrors.firstname = 'First name is required.';
      if (data.lastname === '') tempErrors.lastname = 'Last name is required.';
      if (data.email === '') tempErrors.email = 'Email is required.';
      if (data.number === '') tempErrors.number = 'Contact number is required.';
      if (data.branchLocation === '') tempErrors.branchLocation = 'Branch location is required.';
      if (data.password === '') tempErrors.password = 'Password is required.';
      if (data.type === '') tempErrors.type = 'Type is required.';
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
   };

  

    const empsubmit = (e) => {

      e.preventDefault();

      
      axios.post('https://quantraapi.emedha.in/epm-permission/quantra/v1/employee-permission-update',{firstname,lastname,number,email,password,location,type,id,isview,isadd,isdelete})
      .then(res => {
        if(res.data.status===200){
          alert('Employee edited Successfully')
          
        }
      })
      .catch (err => {
        console.error('error', err);
      })
    };

    const viewhandle = () => {
      setIsview(isview === 1 ? 0 : 1)
      console.log('check', isview === 1 ? 0 : 1); 
    }

    const addhandle = () => {
      setIsadd(isadd === 1 ? 0 : 1)
      console.log('check', isadd === 1 ? 0 : 1); 
    }

    const updatehandle = () => {
      setIsupdate(isupdate === 1 ? 0 : 1)
      console.log('check', isupdate === 1 ? 0 : 1); 
    }

    const deletehandle = () => {
      setIsdelete(isdelete === 1 ? 0 : 1)
      console.log('check', isdelete === 1 ? 0 : 1); 
    }

    const [permissions, setPermissions] = useState({
      'Lead Entry': { View: false, Add: false, Update: false, Delete: false },
      'Lead Registration': { View: false, Add: false, Update: false, Delete: false },
      'Site Survey': { View: false, Add: false, Update: false, Delete: false },
      'Measurement': { View: false, Add: false, Update: false, Delete: false },
      'CAD': { View: false, Add: false, Update: false, Delete: false },
      'Fabrication': { View: false, Add: false, Update: false, Delete: false },
      'OCN&Invoice': { View: false, Add: false, Update: false, Delete: false },
      'Audit': { View: false, Add: false, Update: false, Delete: false },
      'QC Update': { View: false, Add: false, Update: false, Delete: false },
      'QC Approval': { View: false, Add: false, Update: false, Delete: false },
      'Packaging': { View: false, Add: false, Update: false, Delete: false },
      'Logistics': { View: false, Add: false, Update: false, Delete: false },
      'Deilvery': { View: false, Add: false, Update: false, Delete: false },
      'Assign Installment': { View: false, Add: false, Update: false, Delete: false },
      'Installment"': { View: false, Add: false, Update: false, Delete: false },
    });
   


    

      

  return (
    <>
        {/* <h3>Employee Edit</h3> */}
    <Container style={{marginTop:'5vh'}}>
    <Tabs
      defaultActiveKey="Employee Details"
      id="uncontrolled-tab-example"
      className="mb-3 tab-custom"
      style={{color:'black'}}
    >

      
      <Tab eventKey="Employee Details" title="Employee Details"  style={{color:'black'}}>
        <form onSubmit={empsubmit}>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>
                
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px'}}>
                FirstName:
            </label>
            <input
                 type="text"
                name="firstname"
                required
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter First Name"
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Email:
            </label>
            <input
                 type="text"
                 name="email"
                 required
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 placeholder="Enter Email"
                 style={{ width: "30vw", outline: "none" }}
               />
               
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Contact No:
            </label>
            <input
                 type="text"
                name="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter Contact No"
                pattern="[1-9]{1}[0-9]{9}"
                maxLength={10}
                required
                style={{ width: "30vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                BranchLocation:
            </label>
                 <select
               name="branchLocation"
                style={{ width: "30vw", outline: "none" }}
                 value={location}
                 onChange={(e) => setLocation(e.target.value)} 
               >
                <option value="">Select Branch Location</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Banglore">Banglore</option>
                <option value="Delhi">Delhi</option>
                <option value="PESL Unit 2">PESL Unit 1</option>
                <option value="PESL Unit 2">PESL Unit 2</option>
                <option value="Head Office"> Head Office</option>
               </select>
            </div>
            </div>
            <div className='formRight d-flex flex-column gap-2'>
            <div className='d-flex flex-column'>           
            <label>
                LastName:
            </label>
            <input
                 type="text"
                name="lastname"
                required
                value={lastname}
                 onChange={(e) => setLastname(e.target.value)}
                 placeholder="Enter Last Name"
                 style={{ width: "30vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Password:
            </label>
                <input type="password" name="password" value={password}  onChange={(e) =>setPassword(e.target.value)} placeholder='Enter Employee Password' style={{width:"30vw" ,outline:"none"}} />
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Type:
            </label>
                <select name='type' style={{width:"30vw" ,outline:"none"}} value={type}
                 onChange={(e) => {setType(e.target.value)}} >
                <option value="">Select Employee Type</option>
                <option value="Marketing Executive<">Marketing Executive</option>
                <option value="Sales Representative">Sales Representative</option>
                <option value="Measurement Techician">Measurement Techician</option>
                <option value="site survery">Site Survery</option>
                <option value="CAD Engineer,Fabrication Confirmation">CAD Engineer,Fabrication Confirmation</option>
                <option value="Fabrication Agent">Fabrication Agent</option>
                <option value="Documentation User">Documentation User</option>
                <option value="OCN invoice">OCN invoice</option>
                <option value="QC User">QC User</option>
                <option value="Logistic User">Logistic User</option>
                <option value="Delivery Agent">Delivery Agent</option>
                <option value="Installation Agent">Installation Agent</option>
                {/* <option value="Fabrication Confirmation">Fabrication Confirmation</option> */}
                <option value="Packing User">Packing User</option>
                <option value="Senior Manager">Senior Manager</option>
                <option value="Admin">Admin</option>
               </select>
            </div>
            </div>    
            </div>
            <Button variant="success" className='my-5 px-5 py-2' style={{marginTop:"10px"}} type='submit'>
              update
           </Button>
        </form>
      </Tab>
      <Tab eventKey="Permission" title="Permission" style={{color:'black'}}>
       <div className='subTabs'>
        <Tabs className='tab-custom'>
        <Tab eventKey="Lead Entry" disabled={type !== 'Sales Representative'} title="Lead Entry">
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
        
    <input type="checkbox" id="View" name="View"  disabled={type !== 'Sales Representative'} checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add" checked={isadd === 1} disabled={type !== 'Sales Representative'}
            onChange={addhandle} />
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1} disabled={type !== 'Sales Representative'}
            onChange={updatehandle}  />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1} disabled={type !== 'Sales Representative'}
            onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>
  {/* <Button variant="primary" className='mt-10' style={{marginTop:"10px", width:"fit-content"}}  onClick={handleSave}>Save</Button> */}
        </div>
        </Tab>

{/*    
        <Tab eventKey="Lead Registration" title="Lead Registration" disabled={ data.type !== ''}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add" checked={isadd === 1}
             onChange={addhandle} />
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
             onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab> */}


        <Tab eventKey="Site Survey" title="Site Survey" disabled={type !== 'site survery'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
         onChange={viewhandle}     />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add"  checked={isadd === 1}
             onChange={addhandle}/>
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
            onChange={updatehandle}   />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle}  />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Measurement" title="Measurement" disabled={ type !== 'Measurement Techician'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
             onChange={viewhandle} />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add"  checked={isadd === 1}
            onChange={addhandle}/>
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
           onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="CAD,fab" title="CAD" disabled={ type !== 'CAD Engineer'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View"  checked={isview === 1}
             onChange={viewhandle}   />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>


        <Tab eventKey="Fabrication" title="Fabrication" disabled={type !== 'Fabrication Agent'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add" checked={isadd === 1}
            onChange={addhandle}/>
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>   
        </Tab>

        <Tab eventKey="OCN&Invoice" title="OCN&Invoice" disabled={type !== 'OCN invoice'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View"  checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
            onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab>

        {/* <Tab eventKey="Audit" title="Audit" disabled={data.type !== 'Audit User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
           onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab> */}

        <Tab eventKey="QC Update" title="QC Update" disabled={type !== 'QC Update'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
           onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"   checked={isdelete === 1}
              onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab>

        <Tab eventKey="QC Approval" title="QC Approval" disabled={type !== 'QC User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle} />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle}  />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
           onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>
        <Tab eventKey="Packaging" title="Packaging" disabled={type !== 'Packing User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
           onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
            onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>
        <Tab eventKey="Logistics" title="Logistics" disabled={type !== 'Logistic User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
           onChange={viewhandle}   />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
             onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Deilvery" title="Deilvery" disabled={type !== 'Delivery Agent'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}   />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
             onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
             onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        {/* <Tab eventKey="Assign Installation" title="Assign Installment" disabled={data.type !== ''}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
       <input type="checkbox" id="view" name="view"  checked={isview === 1}
             onChange={viewhandle}/>
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
             onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab> */}
        <Tab eventKey="Installation" title="Installation" disabled={type !== 'Installation Agent'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    
    <input type="checkbox" id="Delete" name="Delete" checked={isview === 1}
            onChange={viewhandle} />
            <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
  <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
             onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Senior Manager" title="Senior Manager" disabled={type !== 'Senior Manager'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    
    <input type="checkbox" id="Delete" name="Delete" checked={isview === 1}
            onChange={viewhandle} />
            <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
  <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
             onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Admin" title="Admin" disabled={type !== 'Admin'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    
    <input type="checkbox" id="Delete" name="Delete" checked={isview === 1}
            onChange={viewhandle} />
            <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
  <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
             onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        </Tabs>
       </div>

      </Tab>
   
    </Tabs>

    </Container>
    </>
  )
}

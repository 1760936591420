import React,{useState} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';

export const AddEntry = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case
   
  'firstname':
          setFirstName(value);
          break;
        case
   
  'lastname':
          setLastName(value);
          break;
        case
   
  'email':
          setEmail(value);
          break;
        case 'number':
          setNumber(value);
          break;
        case 'branchLocation':
          setBranchLocation(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'type':
          setType(value);
          break;
        default:
          break;
      }
    };

   

    //   const handleCheckboxChange = (tab, permission) => {
    //     setPermissions((prevPermissions) => ({
    //       ...prevPermissions,
    //       [tab]: {
    //         ...prevPermissions[tab],
    //         [permission]: !prevPermissions[tab][permission],
    //       },
    //     }));
    //   };

    //   const handleSave = () => {
    //     // Implement the logic to save the permissions
    //     console.log('Permissions:', permissions);
    //   };



  return (
    <>
    <Container style={{marginTop:'5vh'}}>
        <form>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>

            <div className='d-flex flex-column'>           
            <label>
                Order
            </label>
               <select
               name="branchLocation"
                style={{ outline: "none",width:'20vw' }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select order mode</option>
                <option value="IKEA">IKEA</option>
                <option value="DTA">DTA</option>
                <option value="Web">Web</option>
               
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Lead Source
            </label>
               <select
               name="branchLocation"
                style={{ width: "20vw", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select lead  source</option>
                <option value="Hyderabad">Advertisement</option>
                <option value="Mumbai">Customer Reference</option>
                <option value="Banglore">Dealer Entry</option>
                <option value="Banglore"> Direct Enquiry</option>
                <option value="Banglore"> Facebook</option>
                <option value="Banglore"> Google</option>
                <option value="Banglore"> instagram</option>
                <option value="Banglore"> Trade Show</option>
                <option value="Banglore"> Twitter</option>
                <option value="Banglore"> Website Equiry</option>
                <option value="Banglore"> others</option>
               
               </select>
            </div>
                
                
            <div className='d-flex flex-column'>           
            <label>
                Customer Name:
            </label>
            <input
                 type="text"
                name="firstname"
                value={firstName}
                onChange={handleInputChange}
                 style={{ width: "20vw", outline: "none" }}
                 placeholder="Enter First Name"
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Contact No:
            </label>
            <input
                 type="number"
                name="number"
                value={number}
                onChange={handleInputChange}
                placeholder="Enter Contact No"
                style={{ width: "20vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Email:
            </label>
            <input
                 type="text"
                 name="email"
                 value={email}
                 onChange={handleInputChange}
                 placeholder="Enter Email"
                 style={{ width: "20vw", outline: "none" }}
               />
  
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Address :
            </label>
            <input
                 type="number"
                name="number"
                value={number}
                onChange={handleInputChange}
                placeholder="Enter Contact No"
                style={{ width: "20vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                india:
            </label>
               <select
               name="branchLocation"
                style={{ width: "20vw", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select Branch Location</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Banglore">Banglore</option>
                <option value="Delhi">Delhi</option>
                <option value="PESL Unit 2">PESL Unit 2</option>
                <option value="PESL Unit 2">PESL Unit 2</option>
                <option value="Head Office"> Head Office</option>
               </select>
            </div>
            </div>
            <div className='formRight d-flex flex-column gap-2'>
            <div className='d-flex flex-column'>           
            <label>
                state:
            </label>
               <select
               name="branchLocation"
                style={{ width: "20vw", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select state</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Banglore">Banglore</option>
                <option value="Delhi">Delhi</option>
              
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                City:
            </label>
               <select
               name="branchLocation"
                style={{ width: "20vw", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select Branch Location</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Banglore">Banglore</option>
                <option value="Delhi">Delhi</option>
                <option value="PESL Unit 2">PESL Unit 2</option>
                <option value="PESL Unit 2">PESL Unit 2</option>
                <option value="Head Office"> Head Office</option>
               </select>
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Pincode:
            </label>
                <input type="text"   placeholder='Enter pincode' style={{width:"20vw" ,outline:"none"}} />
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Lead Status:
            </label>
               <select
               name="branchLocation"
                style={{ width: "20vw", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select Lead status</option>
                <option value="Hyderabad">pending</option>
                <option value="Mumbai">Complete</option>
                <option value="Banglore">close</option>
               </select>
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Sales Representative:
            </label>
            <select name='type' style={{width:"20vw" ,outline:"none"}} value={type}
                 onChange={handleInputChange} >
                <option value="">Select Sales Representative</option>
                <option value="Marketing Executive<">Rakesh Kumar</option>
                <option value="Sales Representative">Ankit Gautam</option>
                <option value="Measurement Techician">Sonu Kumar</option>
               
               </select>
                
            </div>


            </div>
            </div>
            <Button variant="primary" className='my-5' style={{marginTop:"10px"}}>
                Submit
      </Button>
        </form>

    </Container>
    </>
  )
}

import React, { useState,useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


export default function Delivery() {
  const navigate =useNavigate()

  const [delivery, setDelivery] = useState([])
  const [page, setPage] = useState(10)
  const [search, setSearch] = useState('')
  const [cpage, setCpage] = useState(1)

  const pagehandle = (e,val) =>{
    setCpage(val);
  }

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = delivery.slice(indexOffpage,indexOflpage)
  useEffect(() => {
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/reg-data/lead-entry-all')
    .then(res => {
      const rev = res.data.data.reverse()
      setDelivery(rev);
      console.log(res.data.data);
    })
    .catch(err => {
      console.error('error',err)
    })
  },[])

  const getdatasearch = (text) =>{
    console.log(text);
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/search-leaddata',{text})
    
    .then(res => {
      setDelivery(res.data.message);
      console.log(res.data.message);
    })
    .catch(err => {
      console.error('error',err)
    })
  }
  return (
    <>
    <Container>
 
    <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>DELIVERY DETAILS</h5></div>
       <div className='headingRight'> Dashboard/Delivery details</div>
     </div>

    
    <div className='mainContent'>
    <div className='pageContent'>
     {/* <div className='addEmployee'>
       <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
         Add Employee
       </div>
     </div> */}
     <div className='card-body'>
       <div className="table-responsive">
         <div className='row11 d-flex justify-content-between align-items-center mt-4'>
           
         <div className='row-1 p-2'>
           <label>
             Show
             <select onChange={(e)=>setPage(e.target.value)}>
               <option value="10">10</option>
               <option value="25">25</option>
               <option value="50">50</option>
             </select>
             Entries
           </label>
           
           {/* <div className='btn d-flex justify-content-end' style={{width:"60vw"}}>
        <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
    </div> */}
    <h4 className='my-3'>Total Records : {delivery.length}</h4>
           </div>
           
           <div className='row-2 my-4'>
            {/* <div className='my-3'>
           <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
           </div> */}
             <label>
               Search:
               <input type="text" name="search"  onChange={(e) => getdatasearch(e.target.value)}/>
             </label>
             
           </div>

 
         </div>
 
 {/* table */}
 
 
 
 
 
 
     <Table striped bordered>
       <thead>
       <tr>
        <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th><span className='d-flex align-items-center gap-2'><span> ISELL No</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
        {/* <th> <span className='d-flex align-items-center gap-2'> <span>Order ID  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
        <th> <span className='d-flex align-items-center gap-2'> <span>Customer Name</span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span>	OCN No</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span>Invoice No</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span> Approved By</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span>Approval Date & Time</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        {/* <th> <span className='d-flex align-items-center gap-2'> <span>	QC Done By</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2 '> <span>QC Date & Time</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span>	Approved by HO</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span>Ho Date & Time</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
        {/* <th> <span className='d-flex align-items-center gap-2'> <span>Logistics Approved by</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
        <th> <span className='d-flex align-items-center gap-2'> <span>Logistics Approved Date & Time</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        {/* <th> <span className='d-flex align-items-center gap-2'> <span>Transporter name</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
        <th> <span className='d-flex align-items-center gap-2'> <span>Vehicle Driver name</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span>Vehicle no</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        <th> <span className='d-flex align-items-center gap-2'> <span>ETA Delivery date</span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
        {/* <th> <span className='d-flex align-items-center gap-2 '><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
      </tr>
       </thead>
       <tbody>
        {
         currentpost.map(item => (
            <tr key={item.id}>
            <td className='sno'> {item.id}</td>
            <td className='firstname'>{item.isell_no}</td>
            {/* <td className='lastname'></td> */}
            <td className='email'>{item.customerName}</td>
            <td className='password'>{item.doc_OCN_Update_No}</td>
            <td className='contact'>{item.doc_Invoice_No}</td>
            <td className='branch'>{item.approved_by}</td>
            <td className='branch'>{item.Doc_Ocn_Approved_date_and_time}</td>
            {/* <td className='created'></td>
            <td className='updated'></td>
            <td className='updated'></td>
            <td className='updated'></td> */}
            {/* <td className='updated'>{item.logistic_approved_by}</td> */}
            <td className='updated'>{item.Log_materialDateAndTime}</td>
            {/* <td className='updated'></td> */}
            <td className='updated'>{item.log_driver_name}</td>
            <td className='updated'>{item.log_lorry_no}</td>
            <td className='updated'>{item.despatch_delivery_date_time}</td>
            {/* <td className='status'> <Button variant="success" onClick={()=>navigate('/deliveryupdate')}>update</Button></td> */}
           
          </tr>
          ))
        }
       </tbody>
     </Table>
       </div>     
     </div>
     <Container className='d-flex justify-content-end my-3'>
      {/* <Paginationstable/> */}
      <Stack spacing={3}>
      <Pagination count={Math.ceil(delivery.length / page)} page={cpage} onChange={pagehandle} variant="outlined" shape="rounded" />
    </Stack>
     </Container>
    </div>
    </div>
    </Container>
    
    </>
  )
}

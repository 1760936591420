import React,{useState} from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';

export default function ViewFanricationjob() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case
   
  'firstname':
          setFirstName(value);
          break;
        case
   
  'lastname':
          setLastName(value);
          break;
        case
   
  'email':
          setEmail(value);
          break;
        case 'number':
          setNumber(value);
          break;
        case 'branchLocation':
          setBranchLocation(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'type':
          setType(value);
          break;
        default:
          break;
      }
    };
  return (
    <div>
          <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"black",fontWeight:'500'}}>Add Measurement Sheet</h5></div>
     </div>
          
    

        {/* <Container className='my-5'> */}
        <form action="">
            <div className='my-4'>
            <h3>Job Information</h3>
            </div>
        <div className='col-md-12'>
                
                <div className='row'>
                 
                <div className='col-md-12 mb-2'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                ISELL No
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Order Recieved on
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                    
                </div>
                {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                    <button>+ Add more</button>
                </div> */}
                </div>
               
                </div>

                <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Customer Name
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Customer Address
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                </div>
                </div>
                 
                 <Container className='my-4'>
                        <h2>Fabrication Department</h2>
                <div className='col-md-12'>
                    <div className="row">
                    <div className='col-md-2'>           
                <label>
                Fabrication Requested On:
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                </div>

                <div className="row">
                    <div className='col-md-12'>
                          <label htmlFor="">Choose one:</label><br />
                          <label htmlFor="">Single :</label>
                          <input type="radio" value='single' name='choose' checked style={{padding:'100vh'}} /> <br />
                          <label htmlFor="">Multiple :</label>
                          <input type="radio" value='multiple' name='choose'/>
                    </div>
                </div>
                </div>
                
                <div className='col-md-12'>
                
               <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Slab 1 Issue Date
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Slab 2 Issue Date
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                </div>

                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Fabrication Start Date
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Fabrication End Date
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                </div>
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Fabrication Done By:
            </label>
            <select name='type' style={{width:"25vw" ,outline:"none"}} value={type}
                 onChange={handleInputChange} >
                <option value="">Select </option>
                <option value="Marketing Executive<">KAMESHWAR RAO G</option>
                <option value="Marketing Executive<">RAMESH KUMAR</option>
               </select>
                </div>
    
                </div>
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Informed to DOC team on:
            </label>
            <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
    
                </div>
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-6'>  
                <label>
                Attachment Packing List:
            </label><br />
            <input
                     type="file"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                 <img src="https://cdn.pixabay.com/photo/2023/11/20/13/48/butterfly-8401173_1280.jpg" alt="" />
                </div>

                <div className="row">
                    <div className='col-md-6'>
                          <label>
                Remarks
            </label><br />

            <textarea name="" id="" cols="20" rows="4" placeholder='Fabrication End done' style={{width:'90%'}}></textarea> 
                
                    </div>
                </div>
                </div>
                
                </Container>

                
        <Button variant="primary" className='my-2'>
                Submit
      </Button>
        </form>
    </div>
  )
}

import React, { useState,useEffect } from 'react'
// import "./index.css";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


export default function Siteservery() {
  const navigate =useNavigate()
  const [data, setData] = useState([])
  const [page,setPage] = useState(10)
  const [search,setSearch] = useState("")
  const [cpage, setCpage] = useState(1)

  const pagehandle = (e,val) =>{
    setCpage(val);
  }

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = data.slice(indexOffpage,indexOflpage)

  useEffect(() => {
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/reg-data/lead-entry-all')
    .then(res => {
      const rev = res.data.data.reverse()
      setData(rev);
      console.log(res.data.data);
    })
    .catch(err => {
      console.error('error',err)
    })
  },[])

  // const searchhandle = (e) => {
  //   const a = e.target.value;
  //   setSearch(a)

  //   axios.post('https://quantraapi.emedha.in/lead/quantra/v1/lead-search-data/lead-entry',{
  //     name:e.target.value
  //   })
  //   .then(res => {
  //     setSearch(res.data.data)
  //   })
  //   .catch(err => {
  //     console.error('error',err)
  //   })
  // }

  const getdatasearch = (text) =>{
    console.log(text);
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/search-leaddata',{text})
    
    .then(res => {
      setData(res.data.message);
      console.log(res.data.message);
    })
    .catch(err => {
      console.error('error',err)
    })
  }
  return (
    <>
    <Container style={{marginTop:'5vh'}}>
 
    <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>Site Servey Report</h5></div>
       <div className='headingRight'> Dashboard/Site Servey Report</div>
     </div>

    
    <div className='mainContent'>
    <div className='pageContent'>
     {/* <div className='addEmployee'>
       <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
         Add Employee
       </div>
     </div> */}
     <div className='card-body'>
       <div className="table-responsive">
         <div className='row11 d-flex justify-content-between align-items-center mt-4'>
           
         <div className='row-1 p-2'>
           <label>
             Show
             <select onChange={(e)=>setPage(e.target.value)}>
               <option value="10">10</option>
               <option value="25">25</option>
               <option value="50">50</option>
             </select>
             Entries
           </label>
           
           {/* <div className='btn d-flex justify-content-end' style={{width:"60vw"}}>
        <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
    </div> */}
    <h4>Total Records : {data.length}</h4>
           </div>
           
           <div className='row-2 my-4'>
            {/* <div className='my-3'>
           <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
           </div> */}
             <label>
               Search:
               <input type="text" name="search"  onChange={(e) =>getdatasearch(e.target.value)}/>
             </label>
             
           </div>

 
         </div>
 
 {/* table */}
 
 
 
 
 
 
     <Table striped bordered>
       <thead>
         <tr>
           <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th><span className='d-flex align-items-center gap-2'><span> lssel No</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'> <span>Reference Moc no  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           <th> <span className='d-flex align-items-center gap-2'> <span>Order Received date </span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Measurement Date</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'> <span>Order id</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           <th> <span className='d-flex align-items-center gap-2'> <span>Customer name </span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span> Customer Number</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Customer Email  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Description  </span>   <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'> <span> </span> Lead Status <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           {/* <th> <span className='d-flex align-items-center gap-5'><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           {/* <th> <span className='d-flex align-items-center gap-5 mx-5' ><span>view</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
         </tr>
       </thead>
       <tbody>
        {
          currentpost.map(item => (
            <tr key={item.id}>
            <td className='sno'> {item.id}</td>
            <td className='firstname'>{item.isell_no}</td>
            {/* <td className='lastname'></td> */}
            <td className='email'>{item.date}</td>
            <td className='password'>{item.meas_start_date}</td>
            {/* <td className='contact'></td> */}
            <td className='branch'>{item.customerName}</td>
            <td className='branch'>{item.mobile}</td>
            <td className='created'>{item.email}</td>
            <td className='updated'>{item.description}</td>
            {/* <td className='status'> <Button variant="info" onClick={()=>navigate("/addsurvey")} >Add survey</Button></td> */}
            {/* <td className='action'> <Button variant="success" onClick={()=>navigate("/viewsurvey")} >view survey report</Button></td> */}
          </tr>
          ))
        }
       </tbody>
     </Table>
       </div>     
     </div>
     <Container className='d-flex justify-content-end my-3'>
      {/* <Paginationstable/> */}
      <Stack spacing={3}>
      <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} variant="outlined" shape="rounded" />
    </Stack>
     </Container>
    </div>
    </div>
    </Container>
    
    </>
  )
}

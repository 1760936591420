import React,{useState} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import './AddEmployee.css'
import axios from 'axios';

export const AddEmployee = () => {

    const [data, setData] = useState({
      firstname: '',
      lastname:'',
      email:'',
      number:'',
      branchlocation:'',
      password:'',
      type:''
    })
  const [isview, setIsview] = useState(0)
  const [isadd, setIsadd] = useState(0)
  const [isupdate, setIsupdate] = useState(0)
  const [isdelete, setIsdelete] = useState(0)

 
  
    const handleChange = (e) => {
      const {name,value} = e.target;
      setData({...data,[name]: value})
    }
    const [errors, setErrors] = useState({});

    const validate = () => {
      let tempErrors = {};
      if (data.firstname === '') tempErrors.firstname = 'First name is required.';
      if (data.lastname === '') tempErrors.lastname = 'Last name is required.';
      if (data.email === '') tempErrors.email = 'Email is required.';
      if (data.number === '') tempErrors.number = 'Contact number is required.';
      if (data.branchLocation === '') tempErrors.branchLocation = 'Branch location is required.';
      if (data.password === '') tempErrors.password = 'Password is required.';
      if (data.type === '') tempErrors.type = 'Type is required.';
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
   };


    const empsubmit = (e) => {

      e.preventDefault();

      if(!validate()) {
        return;
     }
      axios.post('https://quantraapi.emedha.in/epm-permission/quantra/v1/employee-permission',{data,isview,isupdate,isdelete,isadd})
      .then(res => {
        if(res.data.status===200){
          alert('Employee Added Successfully')
        }
      })
      .catch (err => {
        console.error('error', err);
      })
    };

    const viewhandle = () => {
      setIsview(isview === 1 ? 0 : 1)
      console.log('check', isview === 1 ? 0 : 1); 
    }

    const addhandle = () => {
      setIsadd(isadd === 1 ? 0 : 1)
      console.log('check', isadd === 1 ? 0 : 1); 
    }

    const updatehandle = () => {
      setIsupdate(isupdate === 1 ? 0 : 1)
      console.log('check', isupdate === 1 ? 0 : 1); 
    }

    const deletehandle = () => {
      setIsdelete(isdelete === 1 ? 0 : 1)
      console.log('check', isdelete === 1 ? 0 : 1); 
    }

    const [permissions, setPermissions] = useState({
        'Lead Entry': { View: false, Add: false, Update: false, Delete: false },
        'Lead Registration': { View: false, Add: false, Update: false, Delete: false },
        'Site Survey': { View: false, Add: false, Update: false, Delete: false },
        'Measurement': { View: false, Add: false, Update: false, Delete: false },
        'CAD': { View: false, Add: false, Update: false, Delete: false },
        'Fabrication': { View: false, Add: false, Update: false, Delete: false },
        'OCN&Invoice': { View: false, Add: false, Update: false, Delete: false },
        'Audit': { View: false, Add: false, Update: false, Delete: false },
        'QC Update': { View: false, Add: false, Update: false, Delete: false },
        'QC Approval': { View: false, Add: false, Update: false, Delete: false },
        'Packaging': { View: false, Add: false, Update: false, Delete: false },
        'Logistics': { View: false, Add: false, Update: false, Delete: false },
        'Deilvery': { View: false, Add: false, Update: false, Delete: false },
        'Assign Installment': { View: false, Add: false, Update: false, Delete: false },
        'Installment"': { View: false, Add: false, Update: false, Delete: false },
      });


      const handleCheckboxChange = (tab, permission) => {
        setPermissions((prevPermissions) => ({
          ...prevPermissions,
          [tab]: {
            ...prevPermissions[tab],
            [permission]: !prevPermissions[tab][permission],
          },
        }));
      };

      const handleSave = () => {
        
        console.log('Permissions:', permissions);
      };
 
      

  return (
    <>
    <Container style={{marginTop:'5vh'}}>
 <Tabs
      defaultActiveKey="Employee Details"
      id="uncontrolled-tab-example"
      className="mb-3 tab-custom"
      style={{color:'black'}}
    >
       
      
      <Tab eventKey="Employee Details"  title="Employee Details"  style={{color:'black'}}>
        <form onSubmit={empsubmit}>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>
                
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px'}}>
                FirstName:
            </label>
            <input
                 type="text"
                name="firstname"
                required
                value={data.firstname}
                onChange={handleChange}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter First Name"
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Email:
            </label>
            <input
                 type="text"
                 name="email"
                 required
                 value={data.email}
                 onChange={handleChange}
                 placeholder="Enter Email"
                 style={{ width: "30vw", outline: "none" }}
               />
               
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Contact No:
            </label>
            <input
                 type="text"
                name="number"
                value={data.number}
                onChange={handleChange}
                placeholder="Enter Contact No"
                pattern="[1-9]{1}[0-9]{9}"
                maxLength={10}
                required
                style={{ width: "30vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                BranchLocation:
            </label>
               <select
               name="branchLocation"
                style={{ width: "30vw", outline: "none" }}
                 
                 onChange={handleChange} 
               >
                <option value="">Select Branch Location</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Banglore">Banglore</option>
                <option value="Delhi">Delhi</option>
                <option value="PESL Unit 2">PESL Unit 1</option>
                <option value="PESL Unit 2">PESL Unit 2</option>
                <option value="Head Office"> Head Office</option>
               </select>
            </div>
            </div>
            <div className='formRight d-flex flex-column gap-2'>
            <div className='d-flex flex-column'>           
            <label>
                LastName:
            </label>
            <input
                 type="text"
                name="lastname"
                required
                value={data.lastname}
                 onChange={handleChange}
                 placeholder="Enter Last Name"
                 style={{ width: "30vw", outline: "none" }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Password:
            </label>
                <input type="password" name="password" value={data.password}  onChange={handleChange} placeholder='Enter Employee Password' style={{width:"30vw" ,outline:"none"}} />
            </div>
            <div className='d-flex flex-column'>           
            <label>
                Type:
            </label>
            <select name='type' style={{width:"30vw" ,outline:"none"}} value={data.type}
                 onChange={handleChange} >
                <option value="">Select Employee Type</option>
                <option value="Marketing Executive<">Marketing Executive</option>
                <option value="Sales Representative">Sales Representative</option>
                <option value="Measurement Techician">Measurement Techician</option>
                <option value="site survery">Site Survery</option>
                <option value="CAD Engineer,Fabrication Confirmation">CAD Engineer,Fabrication Confirmation</option>
                <option value="Fabrication Agent">Fabrication Agent</option>
                <option value="Documentation User">Documentation User</option>
                <option value="OCN invoice">OCN invoice</option>
                <option value="QC User">QC User</option>
                <option value="Logistic User">Logistic User</option>
                <option value="Delivery Agent">Delivery Agent</option>
                <option value="Installation Agent">Installation Agent</option>
                <option value="Packing User">Packing User</option>
                {/* <option value="Fabrication Confirmation">Fabrication Confirmation</option> */}
                <option value="Senior Manager">Senior Manager</option>
                <option value="Admin">Admin</option>
               </select>
            </div>
            </div>    
            </div>
            <Button variant="success" className='my-5 px-5 py-2' style={{marginTop:"10px"}} type='submit'>
              Save
           </Button>
        </form>
      </Tab>
      <Tab eventKey="Permission" title="Permission" style={{color:'black'}}>
       <div className='subTabs'>
        <Tabs className='tab-custom'>
        <Tab eventKey="Lead Entry" disabled={data.type !== 'Sales Representative'} title="Lead Entry">
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
        
    <input type="checkbox" id="View" name="View"  disabled={data.type !== 'Sales Representative'} checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add" checked={isadd === 1} disabled={data.type !== 'Sales Representative'}
            onChange={addhandle} />
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1} disabled={data.type !== 'Sales Representative'}
            onChange={updatehandle}  />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1} disabled={data.type !== 'Sales Representative'}
            onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>
  {/* <Button variant="primary" className='mt-10' style={{marginTop:"10px", width:"fit-content"}}  onClick={handleSave}>Save</Button> */}
        </div>
        </Tab>

{/*    
        <Tab eventKey="Lead Registration" title="Lead Registration" disabled={ data.type !== ''}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add" checked={isadd === 1}
             onChange={addhandle} />
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
             onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab> */}


        <Tab eventKey="Site Survey" title="Site Survey" disabled={data.type !== 'site survery'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
         onChange={viewhandle}     />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add"  checked={isadd === 1}
             onChange={addhandle}/>
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
            onChange={updatehandle}   />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle}  />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Measurement" title="Measurement" disabled={ data.type !== 'Measurement Techician'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
             onChange={viewhandle} />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add"  checked={isadd === 1}
            onChange={addhandle}/>
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
           onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="CAD" title="CAD,fab" disabled={ data.type !== 'CAD Engineer'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View"  checked={isview === 1}
             onChange={viewhandle}   />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>


        <Tab eventKey="Fabrication" title="Fabrication" disabled={data.type !== 'Fabrication Agent'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Add" name="Add" checked={isadd === 1}
            onChange={addhandle}/>
    <label htmlFor="Add">Add</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>   
        </Tab>

        <Tab eventKey="OCN&Invoice" title="OCN&Invoice" disabled={data.type !== 'OCN invoice'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View"  checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
            onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab>

        {/* <Tab eventKey="Audit" title="Audit" disabled={data.type !== 'Audit User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
           onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab> */}

        <Tab eventKey="QC Update" title="QC Update" disabled={data.type !== 'QC Update'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
           onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"   checked={isdelete === 1}
              onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>
        </div>
        </Tab>

        <Tab eventKey="QC Approval" title="QC Approval" disabled={data.type !== 'QC User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle} />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle}  />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
           onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>
        <Tab eventKey="Packaging" title="Packaging" disabled={data.type !== 'Packing User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}  />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
           onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete"  checked={isdelete === 1}
            onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>
        <Tab eventKey="Logistics" title="Logistics" disabled={data.type !== 'Logistic User'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
           onChange={viewhandle}   />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
             onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Deilvery" title="Deilvery" disabled={data.type !== 'Delivery Agent'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    <input type="checkbox" id="View" name="View" checked={isview === 1}
            onChange={viewhandle}   />
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update" checked={isupdate === 1}
             onChange={updatehandle} />
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
             onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        {/* <Tab eventKey="Assign Installation" title="Assign Installment" disabled={data.type !== ''}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
       <input type="checkbox" id="view" name="view"  checked={isview === 1}
             onChange={viewhandle}/>
    <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
            onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
             onChange={deletehandle}/>
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab> */}
        <Tab eventKey="Installation" title="Installation" disabled={data.type !== 'Installation Agent'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    
    <input type="checkbox" id="Delete" name="Delete" checked={isview === 1}
            onChange={viewhandle} />
            <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
  <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
             onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Senior Manager" title="Senior Manager" disabled={data.type !== 'Senior Manager'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    
    <input type="checkbox" id="Delete" name="Delete" checked={isview === 1}
            onChange={viewhandle} />
            <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
  <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
             onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        <Tab eventKey="Admin" title="Admin" disabled={data.type !== 'Admin'}>
        <div className='d-flex flex-column gap-2 mt-5'>          
       <div className='d-flex align-items-baseline gap-2' >
    
    <input type="checkbox" id="Delete" name="Delete" checked={isview === 1}
            onChange={viewhandle} />
            <label htmlFor="View">View</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
  <input type="checkbox" id="Update" name="Update"  checked={isupdate === 1}
             onChange={updatehandle}/>
    <label htmlFor="Update">Update</label>
  </div>
  <div className='d-flex align-items-baseline gap-2'>
    <input type="checkbox" id="Delete" name="Delete" checked={isdelete === 1}
            onChange={deletehandle} />
    <label htmlFor="Delete">Delete</label>
  </div>

        </div>
        </Tab>

        </Tabs>
       </div>

      </Tab>
   
    </Tabs>

    </Container>
    </>
  )
}

import React,{useState} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';

export const LeadStatus = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case
   
  'firstname':
          setFirstName(value);
          break;
        case
   
  'lastname':
          setLastName(value);
          break;
        case
   
  'email':
          setEmail(value);
          break;
        case 'number':
          setNumber(value);
          break;
        case 'branchLocation':
          setBranchLocation(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'type':
          setType(value);
          break;
        default:
          break;
      }
    };

   

    //   const handleCheckboxChange = (tab, permission) => {
    //     setPermissions((prevPermissions) => ({
    //       ...prevPermissions,
    //       [tab]: {
    //         ...prevPermissions[tab],
    //         [permission]: !prevPermissions[tab][permission],
    //       },
    //     }));
    //   };

    //   const handleSave = () => {
    //     // Implement the logic to save the permissions
    //     console.log('Permissions:', permissions);
    //   };



  return (
    <>
    <Container  style={{marginTop:'2vh'}}>
 {/* <Tabs
      defaultActiveKey="Add leads"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="Employee Details" title="Lead Entry stage"> */}
     {/* <Container className='py-2'>
     <h1 style={{fontWeight:'bold'}}>Close Status</h1>
      <p style={{color:'grey'}}>Confirm the status to measurements or re-measurements</p>
     </Container> */}
        <form>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>

            <div className='d-flex flex-column'>           
            <label>
                Dealer Name
            </label>
               <select
               name="branchLocation"
                style={{ width: "28rem", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Dealer Name</option>
                <option value="IKEA">option 1</option>
                <option value="DTA">option 2</option>
                <option value="Web">option 3</option>
               
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                order Received Date and Time
            </label>
            <input
                 type="datetime-local"
                name="firstname"
                value={firstName}
                onChange={handleInputChange}
                 style={{ width: "28rem", outline: "none" }}
                 placeholder="order Received Date and Time"
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Location 
            </label>
            <input
                 type="text"
                name="firstname"
                value={firstName}
                onChange={handleInputChange}
                 style={{ width: "28rem", outline: "none" }}
                 placeholder="Location (google link copy paste)"
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                select Colour
            </label>
               <select
               name="branchLocation"
                style={{ width: "28rem", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select color</option>
                <option value="Hyderabad">Advertisement</option>
                <option value="Mumbai">Customer Reference</option>
                <option value="Banglore">Dealer Entry</option>
                <option value="Banglore"> Direct Enquiry</option>
                
               
               </select>
            </div>
                
            <div className='d-flex flex-column'>           
            <label>
                Integrated sink
            </label>
               <select
               name="branchLocation"
                style={{ width: "28rem", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select Integrated sink</option>
                <option value="Hyderabad">yes</option>
                <option value="Mumbai">no</option>
                
               
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Name of Design
            </label>
               <select
               name="branchLocation"
                style={{ width: "28rem", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Select Name of Design</option>
                <option value="Hyderabad">Option 1</option>
                <option value="Mumbai">option 2</option>
                <option value="Banglore">option 3</option>
                
               
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                select finish
            </label>
               <select
               name="branchLocation"
                style={{ width: "28rem", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value=""> select finish</option>
                <option value="Hyderabad"> polish</option>
                <option value="Mumbai">super Honed</option>
                <option value="Banglore">Honed</option>
                <option value="Banglore">Brushed</option>
                
               
               </select>
            </div>
           
            </div>
            <div className='formRight d-flex flex-column gap-2'>
            
            <div className='d-flex flex-column'>           
            <label>
                select Technician
            </label>
               <select
               name="branchLocation"
                style={{ width: "28rem", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value=""> select Technician</option>
                <option value="Hyderabad"> sonu Kumar</option>
                <option value="Mumbai">sonu kumar</option>
                <option value="Banglore">ashish</option>                
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Supervisor Name:
            </label>
            <input
                 type="text"
                name="firstname"
                value={firstName}
                onChange={handleInputChange}
                 style={{ width: "28rem", outline: "none" }}
                 placeholder="Supervisor Name"
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Supervisor phone No:
            </label>
            <input
                 type="number"
                name="number"
                value={number}
                onChange={handleInputChange}
                placeholder="Supervisor phone No"
                style={{ width: "28rem", outline: "none" }}
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Measurement Address
            </label>
            <input
                 type="number"
                name="number"
                value={number}
                onChange={handleInputChange}
                placeholder="Measurement Address( google link copy paste)"
                style={{ width: "28rem", outline: "none" }}
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                visit Time
            </label>
            <input
                 type="time"
                name="number"
                value={number}
                onChange={handleInputChange}
                placeholder="visit Time"
                style={{ width: "28rem", outline: "none" }}
               />
                
            </div>

           


            </div>
            </div>
            <Button variant="primary" className='my-5'>
                Submit
      </Button>
        </form>
      {/* </Tab> */}
      
       {/* </div> */}

      {/* </Tab> */}
   
    {/* </Tabs> */}

    </Container>
    </>
  )
}


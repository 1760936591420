import React, { useState,useEffect } from 'react'
// import "./index.css";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import AssignImg from './AssignImg';
import axios from 'axios'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function Assignview(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Container>
       <AssignImg/>
       </Container>
      </Modal.Body>
      
    </Modal>
  );
}

export default function Assign() {
  const [assign, setAssign] = React.useState(false);
  const navigate =useNavigate()

  const [as, setAs] = useState([])
  const[page, setPage] = useState(10)
  const[search,setSearch] = useState('');
  const [cpage, setCpage] = useState(1)

  const pagehandle = (e,val) =>{
    setCpage(val);
  }

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = as.slice(indexOffpage,indexOflpage)

  useEffect(() => {
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/reg-data/lead-entry-all')
    .then(res => {
      const rev = res.data.data.reverse()
      setAs(rev);
      console.log(res.data.data);
    })
    .catch(err => {
      console.error('error',err)
    })
  },[])

  const getdatasearch = (text) =>{
    console.log(text);
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/search-leaddata',{text})
    
    .then(res => {
      setAs(res.data.message);
      console.log(res.data.message);
    })
    .catch(err => {
      console.error('error',err)
    })
  }
  return (
    <>
    <Container >
 
    <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>Assign Installment</h5></div>
       <div className='headingRight'> Dashboard/Assign Installment</div>
     </div>

    
    <div className='mainContent'>
    <div className='pageContent'>
     {/* <div className='addEmployee'>
       <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
         Add Employee
       </div>
     </div> */}
     <div className='card-body'>
       <div className="table-responsive">
         <div className='row11 d-flex justify-content-between align-items-center mt-4'>
           
         <div className='row-1 p-2'>
           <label>
             Show
             <select onChange={(e)=>setPage(e.target.value)}> 
               <option value="10">10</option>
               <option value="25">25</option>
               <option value="50">50</option>
             </select>
             Entries
           </label>
           
           {/* <div className='btn d-flex justify-content-end' style={{width:"60vw"}}>
        <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
    </div> */}
    <h5 className='my-3'>Total Records : {as.length}</h5>
           </div>
           
           <div className='row-2 my-4'>
            {/* <div className='my-3'>
           <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
           </div> */}
             <label>
               Search:
               <input type="text" name="search"  onChange={(e) => getdatasearch(e.target.value)}/>
             </label>
             
           </div>

 
         </div>
 
 {/* table */}
 
 
 
 
 
 
     <Table striped bordered>
       <thead>
         <tr>
           <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th><span className='d-flex align-items-center gap-2'><span> Packing images</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Before Delivery images </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>After Delivery images </span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Delivery Date & Time </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Delivery By </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Driver name </span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span> Drivered To</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Drivered To phone  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Drivery Concerns  </span>   <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
         </tr>
       </thead>
       <tbody>
        {
          currentpost.map(item => (
            <tr key={item.id}>
            <td className='sno'>{item.id}</td>
              {/* <td className='firstname'><Button variant="success" onClick={() => setAssign(true)}>view</Button> <Assignview
           show={assign}
           onHide={() => setAssign(false)}
         /></td> */}
              {/* <td className='lastname'><Button variant="success" onClick={() => setAssign(true)}>view</Button></td> */}
              {/* <td className='email'><Button variant="success" onClick={() => setAssign(true)}>view</Button></td> */}
              <td className='password'></td>
              <td className='contact'></td>
              <td className='branch'></td>
              <td className='branch'></td>
              <td className='created'></td>
              <td className='updated'></td>
              <td className='status'> </td>
             
            </tr>
          ))
          }
       </tbody>
     </Table>
       </div>     
     </div>
     <Container className='d-flex justify-content-end my-3'>
      {/* <Paginationstable/> */}
      <Stack spacing={3}>
      <Pagination count={Math.ceil(as.length / page)} page={cpage} onChange={pagehandle} variant="outlined" shape="rounded" />
    </Stack>
     </Container>
    </div>
    </div>
    </Container>
    
    </>
  )
}

import React,{useState} from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';

export default function Addmeasurementsheet() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case
   
  'firstname':
          setFirstName(value);
          break;
        case
   
  'lastname':
          setLastName(value);
          break;
        case
   
  'email':
          setEmail(value);
          break;
        case 'number':
          setNumber(value);
          break;
        case 'branchLocation':
          setBranchLocation(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'type':
          setType(value);
          break;
        default:
          break;
      }
    };
  return (
    <div>
        {/* <Container> */}
        <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"black",fontWeight:'500'}}>Add Measurement Sheet</h5></div>
     </div>
          
          <Container className='my-5'>
        <form action="">
        <div className='form d-flex gap-4'>
                
                <div className='formLeft d-flex flex-column gap-2'>
    
                <div className='d-flex flex-column'>           
                <label>
                    ISSEL NO
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "30vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='d-flex flex-column'>           
                <label>
                    customer name
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "30vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                    
                </div>
    
                <div className='d-flex flex-column'>           
                <label>
                    measurement start Date 
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "30vw", outline: "none" }}
                     placeholder="Location (google link copy paste)"
                   />
                    
                </div>

                <div className='d-flex flex-column'>           
                <label>
                    site pictures
                </label>
                <input
                     type="file"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "30vw", outline: "none" }}
                     placeholder="Location (google link copy paste)"
                   />
                    
                </div>

                <div className='d-flex flex-column'>           
                <label>
                   HOB Pictures 
                </label>
                <input
                     type="file"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "30vw", outline: "none" }}
                     placeholder="Location (google link copy paste)"
                   />
                    
                </div>
    
               
               
                </div>
                <div className='formRight d-flex flex-column gap-2'>
                
                <div className='d-flex flex-column'>           
                <label>
                order Received on
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "30vw", outline: "none" }}
                     placeholder="order Received on"
                   />
                </div>
    
                <div className='d-flex flex-column'>           
                <label>
                   Measurement Done by
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "30vw", outline: "none" }}
                     placeholder="Supervisor Name"
                   />
                    
                </div>
    
                <div className='d-flex flex-column'>           
                <label>
                    measurement start Time
                </label>
                <input
                     type="number"
                    name="number"
                    value={number}
                    onChange={handleInputChange}
                    placeholder="Supervisor phone No"
                    style={{ width: "30vw", outline: "none" }}
                   />
                    
                </div>
                <div className='d-flex flex-column'>           
                <label>
                    Sink Pictures
                </label>
                <input
                     type="file"
                    name="number"
                    value={number}
                    onChange={handleInputChange}
                    placeholder="Supervisor phone No"
                    style={{ width: "30vw", outline: "none" }}
                   />
                    
                </div>
    
                </div>

                </div>
        </form>
        </Container>

        {/* <Container className='my-5'> */}
        <form action="">
        <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2'>
                    <mark>Countre Top Dimensions</mark>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                   length
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                length
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                    
                </div>
                {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                    <button>+ Add more</button>
                </div> */}
                </div>
               
                </div>

                <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                    <mark>Wall panel Dimensions</mark>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                   length
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                length
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                    
                </div>
                {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                    <button>+ Add more</button>
                </div> */}
                </div>
               
                </div>

                <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                    <mark>Dados Top Dimensions</mark>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                   length
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                length
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                    
                </div>
                {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                    <button>+ Add more</button>
                </div> */}
                </div>
               
                </div>
                 
                 <Container className='my-5'>
                <div className='col-md-12'>
                    <div className="row">
                        <div className='col-md-4'>
                        <label>
                        Video Upload
                </label>
                <input
                     type="file"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />   
                        </div>
                        <div className='col-md-4'>
                        <label>
                        Measurement & Rough Drawing Upload
                </label>
                <input
                     type="file"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />   
                        </div>
                    </div>
                </div>
                </Container>

                
        <Button variant="primary" className='my-2'>
                Submit
      </Button>
        </form>
        {/* </Container> */}
        {/* </Container> */}
    </div>
  )
}

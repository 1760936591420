import React,{useState} from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';

export default function UpdateOCN() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case
   
  'firstname':
          setFirstName(value);
          break;
        case
   
  'lastname':
          setLastName(value);
          break;
        case
   
  'email':
          setEmail(value);
          break;
        case 'number':
          setNumber(value);
          break;
        case 'branchLocation':
          setBranchLocation(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'type':
          setType(value);
          break;
        default:
          break;
      }
    };
  return (
    <div>
          <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"black",fontWeight:'500'}}>UPDATE OCN & INVOICE</h5></div>
     </div>
          
    

        {/* <Container className='my-5'> */}
        <form action="">
            <div className='my-4'>
            <h3>Job Information</h3>
            </div>
        <div className='col-md-12'>
                
                <div className='row'>
                 
                <div className='col-md-12 mb-2'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                ISELL No
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                Order Recieved on
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                    
                </div>
                {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                    <button>+ Add more</button>
                </div> */}
                </div>
               
                </div>

                <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Customer Name
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                 Address
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                </div>
                </div>
                 
                 <Container className='my-4'>
                        <h2>Fabrication Details</h2>
                        <div className='row'>
                 
                 <div className='col-md-12 mb-2'>
                 </div>        
     
                 <div className='col-md-4'>  
                 <label>
                 Fabrication Complete Date:
                 </label>
                 <input
                      type="text"
                     name="firstname"
                     value={firstName}
                     onChange={handleInputChange}
                      style={{ width: "25vw", outline: "none" }}
                      placeholder="ISSEL NO"
                    />
                 </div>
     
                 <div className='col-md-2'>           
                 <label>
                 Fabrication Done By:
                 </label>
                 <input
                      type="text"
                     name="firstname"
                     value={firstName}
                     onChange={handleInputChange}
                      style={{ width: "25vw", outline: "none" }}
                      placeholder="ISSEL NO"
                    />
                     
                 </div>
                 {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                     <button>+ Add more</button>
                 </div> */}
                 </div>
                <div className='col-md-12'>
                    <div className="row">
                    <div className='col-md-2'>           
                <label>
                Document Prepared By:
                </label>
                <input
                     type="text"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   /> 
                </div>
                </div>

                </div>

                </Container>

                <Container className='my-4'>
                        <h2>Documentation Department</h2>
                        <div className='row'>
                 
                 <div className='col-md-12 mb-2'>
                 </div>        
     
                 <div className='col-md-4'>  
                 <label>
                 Update OCN:
                 </label>
                 <input
                      type="text"
                     name="firstname"
                     value={firstName}
                     onChange={handleInputChange}
                      style={{ width: "25vw", outline: "none" }}
                      placeholder="ISSEL NO"
                    />
                 </div>
     
                 <div className='col-md-2'>           
                 <label>
                 Update Invoice No:
                 </label>
                 <input
                      type="text"
                     name="firstname"
                     value={firstName}
                     onChange={handleInputChange}
                      style={{ width: "25vw", outline: "none" }}
                      placeholder="ISSEL NO"
                    />
                     
                 </div>
                 {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                     <button>+ Add more</button>
                 </div> */}
                 </div>
                 <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Attachment Invoice
                </label>
                <input
                     type="file"
                    name="firstname"
                    value={firstName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="ISSEL NO"
                   />
                </div>
    
                <div className='col-md-2'>           
                <img src="https://cdn.pixabay.com/photo/2023/11/15/19/51/mushroom-8390888_1280.jpg" alt="" srcset="" style={{maxWidth:'100%',height:'auto'}}/>
                </div>
                </div>
                <h3>Remarks</h3>
                <div className="row">
                    <div className='col-md-6'>
                        <label >Remarks</label><br />
                        <textarea name="" id="" cols="80" rows="4" ></textarea>

                    </div>
                </div>
                </div> 

                </Container>

                
        <Button variant="primary" className='my-2'>
                Submit
      </Button>
        </form>
    </div>
  )
}

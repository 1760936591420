import React,{useState, useEffect} from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import axios from 'axios'

export default function CADviewsheet() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
    const [View,setView] = useState([]);


    const {id} = useParams();
//   alert(id)

    useEffect(() => {
      axios.post('https://quantraapi.emedha.in/view/quantra/v1/lead-entry-view-all-data',{
        id
      })
      .then(res =>{
        setView(res.data.data)
        console.log(res.data.data);
        
      })
      .catch(err =>{
        console.error('error',err)
      })
    },[id])

    console.log('data',View.orderMode);
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setView({...View,[name]:value})
    };
  return (
    <div>
          <div className='heading d-flex justify-content-between'>
       <div className='headingLeft                                  vcxbv                      
       
            cvghgfujhyyt    reeeegffd                                    gbvjjjjjjjjjjuj'>
         <h5 style={{color:"black",fontWeight:'500'}}>CAD VIEW SHEET details ; {id}</h5></div>
     </div>
          
    

        {/* <Container className='my-5'> */}
        <form action="">
            <div className='my-4'>
            {/* <h3>order mode</h3> */}
            </div>
        <div className='col-md-12'>
                
                <div className='row'>
                 
                <div className='col-md-12 mb-2'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
              order mode
                </label>
               
                <input
                     type="text"
                    name="orderMode"
                    value={View.orderMode || ''}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="order mode"
                    
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                phone number
                </label>
                <input
                     type="text"
                    name="mobile"
                    value={View.mobile}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="phnone number"
                   />
                    
                </div>
                {/* <div className='col-md-4' style={{position:'absolute',top:'70px',left:'200px',position:'relative',padding:'5px 10px',border:'none',borderRadius:'10px'}}>
                    <button>+ Add more</button>
                </div> */}
                </div>
               
                </div>

                <div className='col-md-12'>
                
                <div className='row'>
                <div className='col-md-12 mb-2 my-3'>
                </div>        
    
                <div className='col-md-4'>  
                <label>
                Customer Name
                </label>
                <input
                     type="text"
                    name="customerName"
                    value={View.customerName}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder="customer name"
                   />
                </div>
    
                <div className='col-md-2'>           
                <label>
                 Address
                </label>
                <input
                     type="text"
                    name="address"
                    value={View.address}
                    onChange={handleInputChange}
                     style={{ width: "25vw", outline: "none" }}
                     placeholder=" Address"
                   /> 
                </div>
                </div>
                </div>
                 
                 {/* <Container className='my-4'> */}
                        {/* <h2>Fabrication Details</h2> */}
                        <div className='row'>
                 
                 <div className='col-md-12 mb-2'>
                 </div>        
     
                 <div className='col-md-4 my-2'>  
                 <label>
                Description 
                 </label>
                 <input
                      type="text"
                     name="description"
                     value={View.description}
                     onChange={handleInputChange}
                      style={{ width: "25vw", outline: "none" }}
                      placeholder="Description "
                    />
                 </div>
                 </div>
      
        <Button variant="primary" className='my-5'>
                Submit
      </Button>
        </form>
    </div>
  )
}

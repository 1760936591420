import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Measurementsheet() {
  return (
    <div>
        <Container>
 
    <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>MEASUREMENT SHEET</h5></div>
       <div className='headingRight'> Dashboard/Measurement sheet</div>
     </div>

    
    <div className='mainContent'>
    <div className='pageContent'>
     {/* <div className='addEmployee'>
       <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
         Add Employee
       </div>
     </div> */}
     <div className='card-body'>
       <div className="table-responsive">
         <div className='row11 d-flex justify-content-between align-items-center mt-4'>
           
         <div className='row-1 p-2'>
           <label>
             Show
             <select>
               <option value="10">10</option>
               <option value="25">25</option>
               <option value="50">50</option>
             </select>
             Entries
           </label>
           
           {/* <div className='btn d-flex justify-content-end' style={{width:"60vw"}}>
        <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
    </div> */}
           </div>
           
           <div className='row-2 my-4'>
            {/* <div className='my-3'>
           <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
           </div> */}
             <label>
               Search:
               <input type="text" name="search" value=""/>
             </label>
             
           </div>

 
         </div>
 
 {/* table */}
 
 
 
 
 
 
     <Table striped bordered>
       <thead>
         <tr>
           <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th><span className='d-flex align-items-center gap-2'><span> version</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Date </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Approval status </span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td className='sno'> </td>
           <td className='firstname'></td>
           <td className='lastname'></td>
           <td className='email'></td>
           <td className='password'></td>
       
         </tr>
       </tbody>
     </Table>
       </div>     
     </div>
    </div>
    </div>
    </Container>
    </div>
  )
}

import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Btnsidebar from '../SideBtn/Btnsidebar';
import MainRoute from '../SideMainRender/MainRoute/MainRoute';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import { Container } from 'react-bootstrap';
import Swal from 'sweetalert2';

const drawerWidth = 250;
const settings = ['Logout'];

function SideBar(props) {
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

 

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleMenu = (event) => {
     setAnchorElUser(event.currentTarget);
  };
 
  const handleClose = () => {
     setAnchorElUser(null);
  };
 
  const handleLogout = () => {
     console.log('Logout clicked');
     // Add your logout logic here
     handleClose();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingClick = (setting) => {
    
    Swal.fire({
      title: `Are you sure you want to  ${setting}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      
      if(result){
        window.location.reload()
        sessionStorage.removeItem('login');
      }
      
      
    });
  };

  

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window1 !== undefined ? () => window1().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{backgroundColor:'white'}}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            style={{color:'grey'}}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" >
            <Container>
          <img src={require('../Images/qlogo.png')} width='100vh' ></img>
          </Container>
          </Typography>

          
            
            <Container >
            <Box sx={{  display: { xs: 'flex', md: 'flex' }, justifyContent: 'flex-end' }} >        
              <Tooltip title="Open settings">
              <IconButton  onClick={handleMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
             
            >
             {settings.map((setting) => (
  <MenuItem key={setting} onClick={() => handleSettingClick(setting)}>
    <Typography textAlign="center">{setting}</Typography>
  </MenuItem>
))}
             
            </Menu>
          </Box>      
          </Container>
        </Toolbar>

        
      </AppBar>

     
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {/* {drawer} */}
          <Btnsidebar/>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          

          <Btnsidebar/>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 3, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {/* <Typography paragraph style={{border:'1px solid black'}}>
         <MainRoute/>
        </Typography> */}
        <Box>
          <MainRoute/>
        </Box>
        
      </Box>
    </Box>
  );
}

SideBar.propTypes = {
  window1: PropTypes.func,
};

export default SideBar;
import React, { useEffect, useState } from 'react'
// import "./index.css";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { LeadStatus } from './LeadStatus';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


function RegisterLead(props) {
  const [registerShow, setRegisterShow] = React.useState(false);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
       <LeadStatus/>
      </Modal.Body>
      
    </Modal>
  );
}

export const  Reigisterlead = () => {
    const navigate =useNavigate()
    const [registerShow, setRegisterShow] = React.useState(false);

     const [reglead, setReglead] = useState([])
     const [search,setSearch]=useState('') 

     const [page, setPage] = useState(10)

     const [cpage, setCpage] = useState(1)

     const pagehandle = (e,val) =>{
       setCpage(val);
     }
   
     const indexOflpage = cpage * page;
     const indexOffpage = indexOflpage - page;
     const currentpost = reglead.slice(indexOffpage,indexOflpage)

    useEffect(() => {
      axios.post('https://quantraapi.emedha.in/lead/quantra/v1/reg-data/lead-entry-all')
      .then(res => {
        const rev = res.data.data.reverse()
        setReglead(rev);
        console.log(res.data.data);
      })
      .catch(err => {
        console.error('error',err)
      })
    },[])

    const getdatasearch = (text) =>{
      console.log(text);
      axios.post('https://quantraapi.emedha.in/lead/quantra/v1/search-leaddata',{text})
      
      .then(res => {
        setReglead(res.data.message);
        console.log(res.data.message);
      })
      .catch(err => {
        console.error('error',err)
      })
    }
  return (
   <>
   <Container style={{marginTop:'5vh'}}>

   <div className='heading d-flex justify-content-between'>
      <div className='headingLeft'>
        <h5 style={{color:"gray"}}>Register Lead</h5></div>
      <div className='headingRight'> Dashboard/Register Lead</div>
    </div>
   <div className='mainContent'>
   <div className='pageContent'>
    {/* <div className='addEmployee'>
      <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
        Add Employee
      </div>
    </div> */}
    <div className='card-body'>
      <div className="table-responsive">
        <div className='row11 d-flex justify-content-between align-items-center mt-4'>
          
        <div className='row-1 p-2'>
          <label>
            Show
            <select onChange={(e)=>setPage(e.target.value)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            Entries
          </label>
          <h4>Total Records : {reglead.length}</h4>
          </div>
          <div className='row-2 my-5'>
          {/* <div className='my-3'>
           <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
           </div> */}
            <label>
              Search:
              <input type="text" name="search" onChange={(e) => getdatasearch(e.target.value)}/>
            </label>
            
          </div>
        </div>

    <Table striped bordered>
      <thead>
      <tr>
           <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th><span className='d-flex align-items-center gap-2'><span> Dealer Name </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th><span className='d-flex align-items-center gap-2'><span> Order Received Data & Time</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>location  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>color code </span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>integrated sink  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>name of design</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Type of finish </span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span> Technician</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Supervisor name  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>supervisor phone no  </span>   <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'> <span>Measurement Address </span>   <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           <th> <span className='d-flex align-items-center gap-2'> <span>visit time</span>   <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
         </tr>
      </thead>
      <tbody>
        {
          currentpost.map( item => (
          <tr key={item.id}>
          <td className='sno'> {item.id}</td>
          <td className='firstname'>{item.dealerName}</td>
          <td className='firstname'>{item.date}</td>
          <td className='lastname'>{item.googleLocation}</td>
          <td className='email'>{item.colour}</td>
          <td className='password'>{item.sink}</td>
          <td className='contact'>{item.nameOfDesign}</td>
          <td className='branch'>{item.finish}</td>
          <td className='branch'>{item.technician_name}</td>
          <td className='created'>{item.supName}</td>
          <td className='updated'>{item.supPhone}</td>
          {/* <td className='updated'>10/11/2023</td> */}
          <td className='updated'>{item.visitTime}</td>
          {/* <td className='action'> <div className='icons d-flex justify-content-between align-items-center gap-2'> <div className='icon1 p-2 border border-1'>
          < BorderColorIcon style={{ fontSize: 19 }}  onClick={() => setRegisterShow(true)} />
          <RegisterLead
        show={registerShow}
        onHide={() => setRegisterShow(false)}
        style={{zIndex:'1111'}}
      />
          </div>
            </div></td> */}
        </tr>
          ))
        }
      </tbody>
    </Table>
      </div>     
    </div>
    <Container className='d-flex justify-content-end my-3'>
      <Stack spacing={3}>
      <Pagination count={Math.ceil(reglead.length / page)} page={cpage} onChange={pagehandle} variant="outlined" shape="rounded" />
    </Stack>
     </Container>
   </div>
   </div>
   </Container>
   
   </>
  )
}

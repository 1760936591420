import React, { useState , useEffect} from 'react'
// import "./index.css";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Paginationstable from '../PaginationTable/Paginationstable';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import axios  from 'axios';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        
        <Modal.Title id="contained-modal-title-vcenter">
          Do You want to Delete this Employee?
        </Modal.Title>
        
      </Modal.Header>
     
      <Modal.Footer>
          <Button variant="secondary" >
            Yes
          </Button>
          <Button variant="danger" >
           Cancel
          </Button>
        </Modal.Footer>
    </Modal>
  );
}


export default function EMployee() {
  const [modalShow, setModalShow] = React.useState(false);

  const [emp, setEmp] = useState([]);
  const [show, setShow] = useState(10);
  const [cpage, setCpage] = useState(1)

  const pagehandle = (e,val) =>{
    setCpage(val);
  }

  const indexOflpage = cpage * show;
  const indexOffpage = indexOflpage - show;
  const currentpost = emp.slice(indexOffpage,indexOflpage)

  const navigate =useNavigate()

  const handleDeleteClick = (id) => {
     
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('https://quantraapi.emedha.in/epm-permission/quantra/v1/employee-permission-delete',{id})
        .then(() => {
          setEmp(emp.filter(item => item.id !== id))
          console.log('Item deleted!');
          Swal.fire('Deleted','your item has been deleted')
        })
        
      }  
    });
  };

  const getdata = () =>{
    axios.post('https://quantraapi.emedha.in/epm-permission/quantra/v1/employee-permission-show')
    
    .then(res => {
      const rev = res.data.data.reverse()
      setEmp(rev);
      console.log(res.data.data);
    })
    .catch(err => {
      console.error('error',err)
    })
  }


  useEffect(() => {
   getdata()
  },[])


  const getdatasearch = (text) =>{
    console.log(text);
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/search-emp/permission',{text})
    
    .then(res => {
      setEmp(res.data.message);
      console.log(res.data.message);
    })
    .catch(err => {
      console.error('error',err)
    })
  }


  return (
    <>
    <Container >
    <div className='mainContent' >
    <div className='pageContent' >
     {/* <div className='addEmployee'>
       <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
         Add Employee
       </div>
     </div> */}
     <div className='card-body' >
       <div className="table-responsive" >
         <div className='row11 d-flex justify-content-between align-items-center mt-4'>
           
         <div className='row-1 p-2'>
           <label className='show'>
             Show
             <select onChange={(e)=>setShow(e.target.value)}>
               <option value="10">10</option>
               <option value="25">25</option>
               <option value="50">50</option>
             </select>
             Entries
           </label>
          
           
           {/* <div className='btn d-flex justify-content-end' style={{width:"60vw"}}>
        <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
    </div> */}
          <h4>Total Records : {emp.length}</h4>
           </div>
           
           <div className='row-2 my-4'>
            <div className='d-flex justify-content-end my-3'>
           <Button variant="primary" className='my-2' onClick={()=>navigate("/addemp")}>Add Employee</Button>
           </div>
             <label>
               Search:
               <input type="text" name="search" onChange={(e) =>getdatasearch(e.target.value) }/>
             </label>
             
           </div>

 
         </div>
 
 {/* table */}
 
 
 
 
 
 
     <Table striped bordered>
       <thead>
         <tr>
           <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th><span className='d-flex align-items-center gap-2'><span> FirstName</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>LastName  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Email </span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Password </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>ContactNo. </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>BranchLocation </span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span> Type</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>CreatedAt  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>UpdatedAt  </span>   <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span> </span>Status <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
         </tr>
       </thead>
       <tbody>
        {
          currentpost.map((item,index) => (
            <tr key={index}>
            <td className='sno'>{item.id}</td>
              <td className='firstname'>{item.firstName}</td>
              <td className='lastname'>{item.lastName}</td>
              <td className='email'>{item.email}</td>
              <td className='password'>{item.password}</td>
              <td className='contact'>{item.mobileNumber}</td>
              <td className='branch'>{item.location}</td>
              <td className='branch'>{item.type}</td>
              <td className='created'>{item.createdAt}</td>
              <td className='updated'>{item.updatedAt}</td>
              <td className='status'> <Button variant="success">Active</Button></td>
              <td className='action'> <div className='icons d-flex  align-items-center '> 
              
              {/* {item.isUpdate === '1' &&  */}
              <div className='icon1 p-2 border border-1'>
              < BorderColorIcon style={{ fontSize: 19 }} onClick={()=>navigate(`/editemployee/${item.id}`)} />

              </div>
              <div className='icon2 p-2 border border-1'><DeleteIcon style={{ fontSize: 19 }} onClick={() => handleDeleteClick(item.id)} /> </div>
              {/* // } */}
              {/* {item.isDelete === '1' && <div className='icon2 p-2 border border-1'><DeleteIcon style={{ fontSize: 19 }} onClick={() => handleDeleteClick(item.id)} /> </div>} */}
                 {item.isView === '1' && <div className='icon2 p-2 border border-1'><RemoveRedEyeIcon style={{ fontSize: 19 }} onClick={()=>navigate(`/viewemployee/${item.id}`)} /></div> }
                 {item.isAdd === '1' &&  <div className='icon1 p-2 border border-1'><AddIcon style={{ fontSize: 19 }} /> </div>} 
                 
                 </div>
              </td>
            </tr>
          ))
        }
        
        
        
       </tbody>
     </Table>
       </div>     
     </div>
     <Container className='d-flex justify-content-end my-3'>
      {/* <Paginationstable/> */}
      <Stack spacing={3}>
      <Pagination count={Math.ceil(emp.length / show)} page={cpage} onChange={pagehandle} variant="outlined" shape="rounded" />
    </Stack>
     </Container>
    </div>
    </div>
    </Container>
    
    </>
  )
}

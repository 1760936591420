import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
// import { PageRoute } from '../../Screens/PageRoute/PageRoute';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { NotePencil,Ruler} from "phosphor-react";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DescriptionIcon from '@mui/icons-material/Description';
import './Btnsidebar.css'
import PaletteIcon from '@mui/icons-material/Palette';
import TaskIcon from '@mui/icons-material/Task';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigate, useLocation } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck ,faRegistered} from '@fortawesome/free-solid-svg-icons';


const drawerWidth = 258;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);


export default function Btnsidebar() {

    const navigate = useNavigate()
    const path = useLocation()

    const [open, setOpen] = React.useState(true);

    const [menu, setMenu] = React.useState('/')

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    zIndex: 99,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                    backgroundColor: 'rgb(252, 236, 236)'
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <div style={{ height: '100vh', width: '100%', backgroundColor: 'rgb(251, 248, 248)', paddingTop: 90 }} >
                    <button onClick={() => {
                        navigate('/')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 20 }} className={`${path.pathname === '/' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <DashboardIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginTop: 1.5, marginLeft: 14 }} > Dashboard</Typography>
                    </button>


                    <button onClick={() => {
                        setMenu('Employees')
                        navigate('/Employees')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 ,width:"max-content"}} className={`${path.pathname === '/Employees' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <PersonIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Employees & Permission</Typography>
                    </button>

                    <button onClick={() => {
                        setMenu('LeadsEntry')
                        navigate('/LeadsEntry')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/LeadsEntry' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <NotePencil size={20} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >LeadEntry</Typography>
                    </button>
                 
                    <button onClick={() => {
                        setMenu('RegisteredLeads')
                        navigate('/RegisteredLeads')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/RegisteredLeads' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <FontAwesomeIcon icon={faRegistered} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Registered Leads</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('SiteSurveyReport')
                        navigate('/SiteSurveyReport')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/SiteSurveyReport' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <NoteAddIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Site Survey Report</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('SiteMeasurementSheet')
                        navigate('/SiteMeasurementSheet')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15, width:"max-content"}} className={`${path.pathname === '/SiteMeasurementSheet' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                      <Ruler size={24} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Site Measurement Sheet</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('CADDrawingUpload')
                        navigate('/CADDrawingUpload')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15, width:"max-content"}} className={`${path.pathname === '/CADDrawingUpload' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <BorderColorIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >CAD Drawing Upload</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('Fabrication')
                        navigate('/Fabrication')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/Fabrication' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <DescriptionIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Fabrication job</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('OCNInoviceUpdate')
                        navigate('/OCNInoviceUpdate')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15,width:"max-content" }} className={`${path.pathname === '/OCNInoviceUpdate' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <ReceiptLongIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >OCN & Invoice Update</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('AuditApproval')
                        navigate('/AuditApproval')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/AuditApproval' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <TaskIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Audit approval</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('QCUpdate')
                        navigate('/QCUpdate')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/QCUpdate' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <DescriptionIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >QC Update</Typography>
                    </button>
                    
                    <button onClick={() => {
                        setMenu('QCApproval')
                        navigate('/QCApproval')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/QCApproval' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <TaskIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >QC Approval</Typography>
                    </button>

                    <button onClick={() => {
                        setMenu('Packaging')
                        navigate('/Packaging')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/Packaging' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <InventoryIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Packaging</Typography>
                    </button>
                    <button onClick={() => {
                        setMenu('LogisticsList')
                        navigate('/LogisticsList')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/LogisticsList' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <BarChartIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Logistics List</Typography>
                    </button>
                    <button onClick={() => {
                        setMenu('DeliveryDetails')
                        navigate('/DeliveryDetails')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/DeliveryDetails' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <LocalShippingIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Delivery Details</Typography>
                    </button>
                    <button onClick={() => {
                        setMenu('AssignInstallment')
                        navigate('/AssignInstallment')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/AssignInstallment' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        {/* <PaletteIcon style={{ fontSize: 19 }} /> */}
                    <i class="fa-solid fa-user-check"></i>
                    <FontAwesomeIcon icon={faUserCheck} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Assign Installation</Typography>
                    </button>
                    <button onClick={() => {
                        setMenu('Installment')
                        navigate('/Installment')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/Installment' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <BuildIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Installation</Typography>
                    </button>
                    <button onClick={() => {
                        setMenu('Reportanalysis')
                        navigate('/Reportanalysis')
                    }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: 20, marginTop: 15 }} className={`${path.pathname === '/Reportanalysis' ? 'active-sidebar-menu' : 'non-active-sidebar-menu'}`}>
                        <BarChartIcon style={{ fontSize: 19 }} />
                        <Typography className='card-text' style={{ marginLeft: 14, marginTop: 1.5 }} >Report Analysis</Typography>
                    </button>
                </div>
            </Drawer>

            {/* <Main style={{ marginTop: 70, padding: 40 }} open={open}>
                <Box>
                    <PageRoute />
                </Box>
            </Main> */}
        </Box>
    );
}
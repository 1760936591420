
import React, { useState } from 'react';
import styled from 'styled-components';
import SideBar from '../DasboardHeader/SideBar';
import { useNavigate } from 'react-router-dom';
const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
    const handleSubmit = (e) => {
    e.preventDefault();

    if (email === 'admin@gmail.com' && password === '123456') {
      
     sessionStorage.setItem('login','1235sdasdsf52452')
     window.location.reload()
    } else {
      alert('Email or password is incorrect.');
    }
 };


  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>Login</Title>
        <FormField>
          <label>Email:</label>
          <input type="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
        </FormField>
        <FormField>
          <label>Password:</label>
          <input type="password" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} />
        </FormField>
        <SubmitButton type="submit">Submit</SubmitButton>
        <ForgotPassword>Forgot Password?</ForgotPassword>
      </Form>
    </Container>
  );
};

   
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Form = styled.form`
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
`;

const FormField = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ForgotPassword = styled.div`
  text-align: center;
  margin-top: 10px;
  color: #007bff;
  cursor: pointer;
`;

export default LoginPage;

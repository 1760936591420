import React, { useState, useEffect } from 'react'
// import "./index.css";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


export default function Sitemeasure() {
  const navigate =useNavigate()

  const[site, setSite] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(10)
  const [cpage, setCpage] = useState(1)

  const pagehandle = (e,val) =>{
    setCpage(val);
  }

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = site.slice(indexOffpage,indexOflpage)

  useEffect(() => {
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/reg-data/lead-entry-all')
    .then(res => {
      const rev = res.data.data.reverse()
      setSite(rev);
      console.log(res.data.data);
    })
    .catch(err => {
      console.error('error',err)
    })
  },[])

  // const searchbar = (e) => {
  //   const a = e.target.value;
  //   setSearch(a)

  //   axios.post('https://quantraapi.emedha.in/lead/quantra/v1/lead-search-data/lead-entry',{
  //     name:e.target.value
  //   })
  //   .then(res => {
  //     setSite(res.data.data)
  //   })
  //   .catch(err => {
  //     console.error('error',err)
  //   })
  // }

  const getdatasearch = (text) =>{
    console.log(text);
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/search-leaddata',{text})
    
    .then(res => {
      setSite(res.data.message);
      console.log(res.data.message);
    })
    .catch(err => {
      console.error('error',err)
    })
  }


  return (
    <>
    <Container style={{marginTop:''}}>
 
    <div className='heading d-flex justify-content-between'>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>Site Measurement Sheet</h5></div>
       <div className='headingRight'> Dashboard/Site Measurement Sheet</div>
     </div>

    
    <div className='mainContent'>
    <div className='pageContent'>
     {/* <div className='addEmployee'>
       <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
         Add Employee
       </div>
     </div> */}
     <div className='card-body'>
       <div className="table-responsive">
         <div className='row11 d-flex justify-content-between align-items-center mt-4'>
           
         <div className='row-1 p-2'>
           <label>
             Show
             <select onChange={(e)=>setPage(e.target.value)}>
               <option value="10">10</option>
               <option value="25">25</option>
               <option value="50">50</option>
             </select>
             Entries
           </label>
           
           {/* <div className='btn d-flex justify-content-end' style={{width:"60vw"}}>
        <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
    </div> */}
            <h4>Total Records : {site.length}</h4>
           </div>
           
           <div className='row-2 my-4'>
            {/* <div className='my-3'>
           <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
           </div> */}
             <label>
               Search:
               <input type="text" name="search" onChange={(e) =>getdatasearch(e.target.value)}/>
             </label> 
           </div>
         </div>
 
     <Table striped bordered>
       <thead>
         <tr>
           <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th><span className='d-flex align-items-center gap-2'><span> lssel No</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'> <span>Order ID  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           <th> <span className='d-flex align-items-center gap-2'> <span>Customer Name </span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Address </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2 mx-5'><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
         </tr>
       </thead>
       <tbody>
        {
          currentpost.map(item => (
            <tr key={item.id}>
           <td className='sno'> {item.id}</td>
           <td className='firstname'>{item.isell_no}</td>
           {/* <td className='lastname'></td> */}
           <td className='email'>{item.customerName}</td>
           <td className='password'>{item.address}</td>
           <td className='status'> <div className='d-flex'>
            {/* <Button variant="info" onClick={()=>navigate("/addmeasurementsheet")}>Add Measurement sheet</Button> */}
            <Button variant="success" className='mx-2'>view sheet</Button></div></td>
         </tr>
          ))
        }
       </tbody>
     </Table>
       </div>     
     </div>
     <Container className='d-flex justify-content-end my-3'>
      <Stack spacing={3}>
      <Pagination count={Math.ceil(site.length / page)} page={cpage} onChange={pagehandle} variant="outlined" shape="rounded" />
    </Stack>
     </Container>
    </div>
    </div>
    </Container>
    
    </>
  )
}

import React,{useState} from 'react'
import { Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';


export default function AddSurvey() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [branchLocation, setBranchLocation] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      switch (name) {
        case
   
  'firstname':
          setFirstName(value);
          break;
        case
   
  'lastname':
          setLastName(value);
          break;
        case
   
  'email':
          setEmail(value);
          break;
        case 'number':
          setNumber(value);
          break;
        case 'branchLocation':
          setBranchLocation(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'type':
          setType(value);
          break;
        default:
          break;
      }
    };
  return (
    <div>
        <Container>
        <form>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>

            <div className='d-flex flex-column'>           
            <label>
                Dealer Name
            </label>
               <select
               name="branchLocation"
                style={{ width: "25vw", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value="">Dealer Name</option>
                <option value="IKEA">option 1</option>
                <option value="DTA">option 2</option>
                <option value="Web">option 3</option>
               
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                order Received Date and Time
            </label>
            <input
                 type="datetime-local"
                name="firstname"
                value={firstName}
                onChange={handleInputChange}
                 style={{ width: "25vw", outline: "none" }}
                 placeholder="order Received Date and Time"
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Location 
            </label>
            <input
                 type="text"
                name="firstname"
                value={firstName}
                onChange={handleInputChange}
                 style={{ width: "25vw", outline: "none" }}
                 placeholder="Location (google link copy paste)"
               />
                
            </div>

           
           
            </div>
            <div className='formRight d-flex flex-column gap-2'>
            
            <div className='d-flex flex-column'>           
            <label>
                select Technician
            </label>
               <select
               name="branchLocation"
                style={{ width: "25vw", outline: "none" }}
                 value={branchLocation}
                 onChange={handleInputChange} 
               >
                <option value=""> select Technician</option>
                <option value="Hyderabad"> sonu Kumar</option>
                <option value="Mumbai">sonu kumar</option>
                <option value="Banglore">ashish</option>                
               </select>
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Supervisor Name:
            </label>
            <input
                 type="text"
                name="firstname"
                value={firstName}
                onChange={handleInputChange}
                 style={{ width: "25vw", outline: "none" }}
                 placeholder="Supervisor Name"
               />
                
            </div>

            <div className='d-flex flex-column'>           
            <label>
                Supervisor phone No:
            </label>
            <input
                 type="number"
                name="number"
                value={number}
                onChange={handleInputChange}
                placeholder="Supervisor phone No"
                style={{ width: "25vw", outline: "none" }}
               />
                
            </div>

            </div>

           


            </div>
            <div className='col-md-12 my-5'>
               <p>
               In order to ensure that the installation services are performed smoothly, please perform the Site Survey and fill in the following information:
               </p>

               <div className="row">
                 <div className='col-md-6'>
                    <p>1. Is the site ready for Installation Services?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>

                 <div className='col-md-6'>
                    <p>a. Cabinet installed and levelled ?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>
                 <div className='col-md-6'>
                    <p>b. In case of old home, existing countertop removed ?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>
                 <div className='col-md-6'>
                    <p>c. Sink, Faucet, Cooktop details finalised ?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>
                 <div className='col-md-6'>
                    <p>d. Bracket on cabinet have been installed ?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>
                 <div className='col-md-6'>
                    <p>2. Sinks have been received?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>
                 <div className='col-md-6'>
                    <p>3. Cooktops have been received?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>
                 <div className='col-md-6'>
                    <p>4. How would you best describe the job site?</p>
                 </div>
                 <div className='col-md-6'>
                 <label htmlFor="yes">New Home</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">Old Home Renovation</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>

               </div>

               <div className='col-md-12'> 
                 <p>5. Which floor of building will the installation be performed?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
                
               <div className='col-md-12'> 
                 <p>6. If not on the ground floor, Is there is a lift which can be and is allowed to be used for carrying the counter top ?</p>
                 <label htmlFor="yes">yes</label>
                 <input type="radio" name="" id="yes" value='yes' />
                 <label htmlFor="no">no</label>
                 <input type="radio" name="" id="no" value='no' />
                 </div>
               
                 <div className='col-md-12'> 
                 <p>8. If the lift is not suitable, is there any alternative access?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>

               <div className='col-md-12'> 
                 <p>9. What are the dimensions and nature of alternative access?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
                 <p>10. What is the height in metres from ground to the access point?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
                 <p>11. What is the condition and construction of the access floor(s) from the vehicle parking area to the installation site?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
                 <p>12. Are there any narrow passageways, pipes, electrical conduits etc. that may cause obstruction?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
                 <p>13. Are there any parking/unloading restrictions outside the building?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
                 <p>14. Has the customer been told to take permission fr relevant authorities including society to unload the vehicle and to carry out installation job at the site?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
               <p>15. Are there any restriction on time during which installation services cannot be carried out in the society?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
               <p>If yes, what are the timings during which installation cannot be carried out?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
               <p>16. Are there any issues or obstacles which could cause in effective installation and should be brought to the attention of installation team?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
               <p>17. Is there any work in process at the job site where installation has to be performed?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>
               <div className='col-md-12'> 
               <p>18. Is there any other information which requires attention of the installation team?</p>
                 <textarea rows="5" cols="2" style={{padding:'7.52px 12px',overflow:'auto',resize:'vertical',width:'70%'}}></textarea>
               </div>

               <div className='row my-2'>
            <div className='col-md-6'>
                    <p>Customer Signature</p>
                    <input type="file" />
                 </div>
                 <div className='col-md-6'>
                 <p>Technician Signature</p>
                    <input type="file" />
                 </div>
                 </div>
       
            </div>
            
            <Button variant="primary" className=''>
                Submit
      </Button>
        </form>
        </Container>
    </div>
  )
}

import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Dashboard from '../DashBoard/Dashboard'
import Employee from '../EmployeePermission/Employee'
import { AddEmployee } from '../EmployeePermission/AddEmployee'
import Entry from '../Entry/Entry'
import { AddEntry } from '../Entry/AddEntry'
import { Reigisterlead } from '../RegisterLead/Reigisterlead'
import Siteservery from '../SiteServeyReport/SIteservery'
import Sitemeasure from '../SiteMeasurementSheet/Sitemeasure'
import Caddrawing from '../CADDrawing/Caddrawing'
import Fabjob from '../Fabricationjob/Fabjob'
import Invoiceupdate from '../InvoiceUpdate/Invoiceupdate'
import Auditaproval from '../AuditAproval/Auditaproval'
import Qcupdate from '../QCUpdate/Qcupdate'
import Qcapproval from '../QCApproval/Qcapproval'
import Packaging from '../Packaging/Packaging'
import Logistic from '../LogisticList/Logistic'
import Delivery from '../DeliveryDetails/Delivery'
import Assign from '../AsignInsatll/Assign'
import Insallment from '../Installment/Insallment'
import { LeadStatus } from '../RegisterLead/LeadStatus'
import ViewSurveryReport from '../SiteServeyReport/ViewSurveryReport'
import AddSurvey from '../SiteServeyReport/AddSurvey'
import Addmeasurementsheet from '../SiteMeasurementSheet/Addmeasurementsheet'
import Measurementsheet from '../CADDrawing/Measurementsheet'
import ViewFanricationjob from '../Fabricationjob/ViewFanricationjob'
import UpdateOCN from '../InvoiceUpdate/UpdateOCN'
import UpdateAuditdetails from '../AuditAproval/UpdateAuditdetails'
import UpdateQCDetails from '../QCUpdate/UpdateQCDetails'
import ViewQcDetails from '../QCApproval/ViewQcDetails'
import PackagingDetails from '../Packaging/PackagingDetails'
import UpdateLogistics from '../LogisticList/UpdateLogistics'
import DeliveryUpdate from '../DeliveryDetails/DeliveryUpdate'
import ViewInstallment from '../Installment/ViewInstallment'
import UpdateInstallment from '../Installment/UpdateInstallment'
import LoginPage from '../../Loginpage/LoginPage'
import SideBar from '../../DasboardHeader/SideBar'
import CADviewsheet from '../CADDrawing/CADviewsheet'
import { EditEmployee } from '../EmployeePermission/EditEmployee'
import { ViewEmployee } from '../EmployeePermission/ViewEmployee'
import Reportanalysis from '../Reportanalysis/Reportanalysis'
import Viewreportanalysis from '../Reportanalysis/Viewreportanalysis'


export default function MainRoute() {
  return (
   
    <Routes>
        <Route path='/' element={<Dashboard/>} />
        <Route path='/Employees' element={<Employee/>} /> 
        <Route path='/addemp' element={<AddEmployee/>} /> 
        <Route path='/LeadsEntry' element={<Entry/>} /> 
        <Route path='/addentry' element={<AddEntry/>} /> 
        <Route path='/RegisteredLeads' element={<Reigisterlead/>} /> 
        <Route path='/SiteSurveyReport' element={<Siteservery/>} /> 
        <Route path='/SiteMeasurementSheet' element={<Sitemeasure/>} /> 
        <Route path='/CADDrawingUpload' element={<Caddrawing/>} /> 
        <Route path='/Fabrication' element={<Fabjob/>} /> 
        <Route path='/OCNInoviceUpdate' element={<Invoiceupdate/>} /> 
        <Route path='/AuditApproval' element={<Auditaproval/>} /> 
        <Route path='/QCUpdate' element={<Qcupdate/>} /> 
        <Route path='/QCApproval' element={<Qcapproval/>} /> 
        <Route path='/Packaging' element={<Packaging/>} /> 
        <Route path='/LogisticsList' element={<Logistic/>} /> 
        <Route path='/DeliveryDetails' element={<Delivery/>} /> 
        <Route path='/AssignInstallment' element={<Assign/>} /> 
        <Route path='/Installment' element={<Insallment/>} /> 
        <Route path='/leadstatus' element={<LeadStatus/>} /> 
        <Route path='/viewsurvey' element={<ViewSurveryReport/>} /> 
        <Route path='/addsurvey' element={<AddSurvey/>} /> 
        <Route path='/addmeasurementsheet' element={<Addmeasurementsheet/>} /> 
        <Route path='/measuresheet' element={<Measurementsheet/>} /> 
        <Route path='/viewfabricationjob' element={<ViewFanricationjob/>} /> 
        <Route path='/updateOCN' element={<UpdateOCN/>} /> 
        <Route path='/updateauditdetails' element={<UpdateAuditdetails/>} /> 
        <Route path='/updateqcdetails' element={<UpdateQCDetails/>} /> 
        <Route path='/viewqcdetails' element={<ViewQcDetails/>} /> 
        <Route path='/packagingdetails' element={<PackagingDetails/>} /> 
        <Route path='/upatelogistics/id' element={<UpdateLogistics/>} /> 
        <Route path='/cadview/:id' element={<CADviewsheet/>} /> 
        <Route path='/deliveryupdate' element={<DeliveryUpdate/>} /> 
        <Route path='/viewinstallment' element={<ViewInstallment/>} /> 
        <Route path='/editemployee/:id' element={<EditEmployee/>} /> 
        <Route path='/viewemployee/:id' element={<ViewEmployee/>} /> 
        <Route path='/updateinstallment' element={<UpdateInstallment/>} /> 
        <Route path='/Reportanalysis' element={<Reportanalysis/>} /> 
        <Route path='/reportanalysisview/:id' element={<Viewreportanalysis/>} /> 
        <Route path='/side' element={< SideBar />} /> 
       
    </Routes>
    
  )
}

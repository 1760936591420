import React, { useState } from 'react'
// import "./index.css";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowsDownUp} from "phosphor-react";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Paginationstable from '../PaginationTable/Paginationstable';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AddEntry } from './AddEntry';
import Badge from 'react-bootstrap/Badge';
import { LeadStatus } from '../RegisterLead/LeadStatus';
import { useEffect } from 'react';
import axios from 'axios'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      <Modal.Title>create</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <AddEntry/>
      </Modal.Body>
    </Modal>
  );
}

function RegisterLead(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      <Modal.Header closeButton>
      <Modal.Title>create</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <LeadStatus/>
      </Modal.Body>
      
    </Modal>
  );
}

export default function Entry() {
  const [modalShow, setModalShow] = React.useState(false);
  const [registerShow, setRegisterShow] = React.useState(false);
  const navigate =useNavigate()

  const [data, setData] = useState([])
  const [page, setPage] = useState(10)
  const [search,setSearch]=useState('') 
  const [cpage, setCpage] = useState(1)

  const pagehandle = (e,val) =>{
    setCpage(val);
  }

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = data.slice(indexOffpage,indexOflpage)

  useEffect(() => {
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/reg-data/lead-entry-all')
    .then(res => {
      const rev = res.data.data.reverse()
      setData(rev);
      console.log(res.data.data);
    })
    .catch(err => {
      console.error('error',err)
    })
  },[])

  
  // const searchbar = (e) => {
  //   const a = e.target.value;
  //   setSearch(a)

  //   axios.post('https://quantraapi.emedha.in/lead/quantra/v1/lead-search-data/lead-entry',{
  //     name:e.target.value
  //   })
  //   .then(res => {
  //     setData(res.data.data)
  //   })
  //   .catch(err => {
  //     console.error('error',err)
  //   })
  // }

  const getdatasearch = (text) =>{
    console.log(text);
    axios.post('https://quantraapi.emedha.in/lead/quantra/v1/search-leaddata',{text})
    
    .then(res => {
      setData(res.data.message);
      console.log(res.data.message);
    })
    .catch(err => {
      console.error('error',err)
    })
  }



  return (
    <>
    <Container >
 
    <div className='heading d-flex justify-content-between' style={{marginTop:''}}>
       <div className='headingLeft'>
         <h5 style={{color:"gray"}}>Lead Entry</h5></div>
       <div className='headingRight'> Dashboard/Lead Entry</div>
     </div>

    
    <div className='mainContent' >
    <div className='pageContent'>
     {/* <div className='addEmployee'>
       <div className='text-right bg-primary addBtn' style={{width:"fit-content"}}>
         Add Employee
       </div>
     </div> */}
     <div className='card-body'>
       <div className="table-responsive">
         <div className='row11 d-flex justify-content-between align-items-center mt-4'>
           
         <div className='row-1 p-2'>
           <label>
             Show
             <select onChange={(e)=>setPage(e.target.value)}>
               <option value="10">10</option>
               <option value="25">25</option>
               <option value="50">50</option>
             </select>
             Entries
           </label>
           
           {/* <div className='btn d-flex justify-content-end' style={{width:"60vw"}}>
        <Button variant="primary" className='my-2' onClick={()=>navigate("/addentry")}>Add Lead</Button>
    </div> */}
            <h4>Total Records : {data.length}</h4>
           </div>
           
           <div className='row-2 my-4'>
           <div className='d-flex justify-content-end my-3'>
           {/* <Button variant="primary" className='my-2' onClick={() => setModalShow(true)}>Add Lead</Button> */}
           <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        style={{zIndex:'1111'}}
      />
           </div>
             <label>
               Search:   
               <input type="text" name="search"  onChange={(e) => getdatasearch(e.target.value)}/>
             </label>  
           </div>
         </div>
 
     <Table striped bordered>
       <thead>
         <tr >
           <th><span className='d-flex align-items-center gap-2'><span> S.No </span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>       
           <th><span className='d-flex align-items-center gap-2'><span>Customer name</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>       
           <th><span className='d-flex align-items-center gap-2'><span> lssel No</span> <span><ArrowsDownUp size={20} color='grey' /></span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Mobile  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Email </span>  <span> <ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Lead Source </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'> <span>sales man </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           <th> <span className='d-flex align-items-center gap-2'> <span>state </span> <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span> country</span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Address  </span>  <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span>Description  </span>   <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           <th> <span className='d-flex align-items-center gap-2'> <span> </span> Lead Status <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th>
           {/* <th> <span className='d-flex align-items-center gap-2'> <span> </span> Register Lead <span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
           {/* <th> <span className='d-flex align-items-center gap-2'><span>Action</span><span><ArrowsDownUp size={20} color='grey' /> </span> </span></th> */}
         </tr>
       </thead>
       <tbody>
        {
        currentpost.map(val => (
          <tr key={val.id}>
          <td > {val.id}</td>
          <td className='firstname'>{val.customerName}</td>
          <td className='firstname'>{val.isell_no}</td>
          <td className='firstname'>{val.mobile}</td>
          <td className='password'>{val.email}</td>
          <td className='lastname'>{val.leadSource}</td>
          {/* <td className='email'>{val.saleman}</td> */}
          {/* <td className='contact'>{val.state}</td> */}
          <td className='contact'>{val.state}</td>
          <td className='branch'>{val.city}</td>
          <td className='branch'>{val.address}</td>
          <td className='created'>{val.description}</td>
          <td className='updated'>{val.lead_status}</td>
          {/* <td className='status'> <Badge bg="success">complete</Badge></td> */}
          {/* <td className='status'> <Button variant="success" onClick={() => setRegisterShow(true)}>RegisterLead</Button> <RegisterLead
       show={registerShow}
       onHide={() => setRegisterShow(false)}
       style={{zIndex:'1111'}}
     /></td> */}
          {/* <td className='action'> <div className='icons d-flex justify-content-between align-items-center '> {val.isUpdate === 1 && <div className='icon1 p-2 border border-1'>
          < BorderColorIcon style={{ fontSize: 19 }}  onClick={() => setModalShow(true)} />
          </div>}
        
            </div></td> */}
        </tr>
        ))
        }
         
       </tbody>
     </Table>
       </div>     
     </div>
     <Container className='d-flex justify-content-end my-3'>
      <Stack spacing={3}>
      <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} variant="outlined" shape="rounded" />
    </Stack>
     </Container>
    </div>
    </div>
    </Container>
    
    </>
  )
}
